import React, { FC } from 'react';
import { Button } from '../../../../../components/ui/Button/Button';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { MainLocales } from '../../../../../locales/locales.interfaces';
import dialogsStyle from '../Dialogs.module.scss';

interface Props {
  email: string;
  isResendingEmail: boolean;
  resendEmail: () => void;
}

export const ConfirmationEmailSent: FC<Props> = ({ email, resendEmail, isResendingEmail }) => {
  const { t } = useTranslation<MainLocales>('mainLocales');

  return (
    <div className={dialogsStyle.content}>
      <div className={dialogsStyle.left}>
        <h1>{t('confirmationEmailSentHeader')}</h1>
        <p>{t('confirmationEmailSentCheckEmail', { email })} </p>
        <div>
          <span>{t('didntReceivedEmail')}</span>
          <Button type='link' onClick={resendEmail} disabled={isResendingEmail}>{t('sendAgain')}</Button>
        </div>
      </div>
    </div>
  );
};
