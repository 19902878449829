import { Input as AntInput } from 'antd';
import { PasswordProps } from 'antd/es/input';
import { InputProps as AntInputProps } from 'antd/lib/input';
import cx from 'classnames';
import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { Control, FieldError } from 'react-hook-form/dist/types/form';
import { MAX_INPUT_LENGTH } from '../../../constants/common';
import { handleInputFormat } from '../../../utils/input-handlers';
import { WithLabelAndError, WithLabelAndErrorProps } from '../hoc/WithLabelAndError';
import { Icon } from '../Icon/Icon';
import style from './Input.module.scss';

export interface InputProps extends WithLabelAndErrorProps, Pick<PasswordProps, 'iconRender'>,
  Pick<AntInputProps, 'maxLength' | 'pattern' | 'onInput' | 'disabled' | 'autoFocus' | 'onChange'> {
  className?: string;
  control: Control;
  defaultValue?: string;
  error: FieldError | null;
  inputClassName?: string;
  label?: string;
  name: string;
  onlyCharacters?: boolean;
  onlyNumbers?: boolean;
  rules?: any;
  type?: string;
}

export const Input: FC<InputProps> =
  ({
     required,
     label,
     error,
     className,
     inputClassName,
     maxLength = MAX_INPUT_LENGTH,
     onlyCharacters,
     onlyNumbers,
     type = 'input',
     ...props
   }) => {
    return (
      <WithLabelAndError error={error} required={required} label={label} className={className}>
        <Controller
          as={type === 'password' ? AntInput.Password : AntInput}
          iconRender={(visible: boolean) => visible ? <Icon type='ic-eye-open'/> : <Icon type='ic-eye-close'/>}
          size='large'
          rules={{ required, maxLength }}
          className={cx(style.input, inputClassName)}
          maxLength={maxLength}
          onInput={handleInputFormat({ maxLength, onlyCharacters })}
          type={onlyNumbers ? 'number' : 'text'}
          aria-invalid={error ? 'true' : 'false'}
          {...props}
        />
      </WithLabelAndError>
    );
  };
