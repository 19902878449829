import { message } from 'antd';
import { MessageApi } from 'antd/lib/message';
import React from 'react';
import { NotificationsLocales } from '../../../locales/locales.interfaces';
import { BusinessErrorCode } from '../../../types/enums/business-error-code.enum';
import { ErrorResponse } from '../../../types/interfaces/error-response.interface';
import './Notifications.scss';
import { Toast } from './Toast/Toast';

export declare type MessageType = Pick<MessageApi, 'success' | 'error' | 'info'>;

interface ErrorNotificationHandlingOptions {
  config?: { [key in BusinessErrorCode]?: keyof NotificationsLocales };
  defaultNotification?: keyof NotificationsLocales;
}

const showNotification = (type: keyof MessageType, translationKey: keyof NotificationsLocales) =>
  message[type]({
    content: <Toast type={type} translationKey={translationKey}/>,
    icon: <div style={{ display: 'none' }}/>,
  });

/**
 * Show error notification based on errorCode from API or define custom one.
 */
export const showErrorNotification = (error: ErrorResponse, options: ErrorNotificationHandlingOptions = {}) => {
  const { config, defaultNotification = 'responseFailed' } = options;
  const translationKey: keyof NotificationsLocales = (config && config[error.errorCode]) || defaultNotification;
  showNotification('error', translationKey);
};

export const showSuccessNotification = (translationKey: keyof NotificationsLocales) => {
  showNotification('success', translationKey);
};

export const showInfoNotification = (translationKey: keyof NotificationsLocales) => {
  showNotification('info', translationKey);
};
