import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { Loader } from '../../components/ui/Loader/Loader';
import { usePatient } from '../../hooks/usePatient';
import { useWindowHeight } from '../../hooks/useWindowHeight';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import { store } from '../../store/store';
import breakpoints from '../../styles/breakpoints.scss';
import effects from '../../styles/effects.scss';
import { BookingPage } from './BookingPage';

export const BookingPageContainer = () => {
  const { state } = useContext(store);
  const windowWidth = useWindowWidth();
  const windowHeight = useWindowHeight();
  const [isCalendlyFormDirty, setIsCalendlyFormDirty] = useState(false);
  const { isLoadingPatient, patient } = usePatient();

  useEffect(() => {
    const isCalendlyDateSelectedEvent = (e: any) => e.data.event && e.data.event.indexOf('calendly.date_and_time_selected') === 0;
    const onMessageListener = (e: any) => {
      if (isCalendlyDateSelectedEvent(e)) {
        setIsCalendlyFormDirty(true);
      }
    };

    window.addEventListener('message', onMessageListener);

    return () => {
      window.removeEventListener('message', onMessageListener);
    };
  });

  // IMPORTANT widget height depends on locations number
  const widgetStyle: CSSProperties = (() => {
    switch (true) {
      case (windowWidth >= parseInt(breakpoints.laptopM)): {
        return { height: 800 };
      }
      case (windowWidth >= parseInt(breakpoints.laptopS)): {
        return { height: 800, overflow: 'hidden' };
      }
      case (windowWidth >= parseInt(breakpoints.tablet)): {
        return { height: 625, overflow: 'hidden', borderRadius: 8, boxShadow: effects.baseBoxShadow };
      }
      default: {
        // 150px - header + footer, 3rem - additional bottom margin
        return { height: `calc(${windowHeight}px - 150px - 3rem)`, overflow: 'hidden' };
      }
    }
  })();

  if (isLoadingPatient) {
    return <Loader fullscreen size='large'/>;
  }

  return (
    <BookingPage
      widgetStyle={widgetStyle}
      email={state.currentUser?.attributes.email}
      isCalendlyFormDirty={isCalendlyFormDirty}
      name={patient?.patient.name}
    />
  );
};
