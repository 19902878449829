import { Dispatch, StoreActionType } from '../store/store-types';
import { HomePageDialogState } from '../types/enums/home-page-dialog-state.enum';

export const showHomePageDialog = (dispatch: Dispatch, homePageDialog: HomePageDialogState) => {
  dispatch({ type: StoreActionType.SET_HOME_PAGE_DIALOG_STATE, payload: homePageDialog });
  dispatch({ type: StoreActionType.SET_SHOW_HOME_PAGE_DIALOG, payload: true });
};

export const hideHomePageDialog = (dispatch: Dispatch) => {
  dispatch({ type: StoreActionType.SET_SHOW_HOME_PAGE_DIALOG, payload: false });
  dispatch({ type: StoreActionType.SET_HOME_PAGE_DIALOG_STATE, payload: null });
};
