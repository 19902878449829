import { joiResolver } from '@hookform/resolvers';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../components/ui/Button/Button';
import { Input } from '../../../components/ui/Input/Input';
import { useTranslation } from '../../../hooks/useTranslation';
import { MainLocales } from '../../../locales/locales.interfaces';
import style from '../ChangeForms.module.scss';
import { changePasswordInputSchema, ChangePasswordInputsSchema } from './validation';

interface Props {
  onDataSubmit: (data: ChangePasswordInputsSchema) => void;
}

export const ChangePasswordForm: FC<Props> = ({ onDataSubmit }) => {
  const { t } = useTranslation<MainLocales>('mainLocales');
  const { control, handleSubmit, errors, formState } = useForm<ChangePasswordInputsSchema>({
    resolver: joiResolver(changePasswordInputSchema),
  });

  return (
    <form className={style.form} onSubmit={handleSubmit(onDataSubmit)}>
      <div className={style.inputsContainer}>
        <Input
          name='currentPassword'
          control={control}
          defaultValue=''
          label={t('currentPassword')}
          error={errors.currentPassword!}
          className={style.column}
          iconRender={() => null}
          type='password'
        />
        <div className={style.column} />
      </div>
      <div className={style.inputsContainer}>
      <Input
        name='newPassword'
        control={control}
        defaultValue=''
        label={t('newPassword')}
        error={errors.newPassword!}
        className={style.column}
        type='password'
      />
        <Input
          name='newPasswordRepeat'
          control={control}
          defaultValue=''
          label={t('repeatPassword')}
          error={errors.newPasswordRepeat!}
          className={style.column}
          iconRender={() => null}
          type='password'
        />
      </div>
      <Button
        type='primary'
        htmlType='submit'
        disabled={!formState.isDirty || formState.isSubmitting}
        className={style.button}
      >
        {t('update')}
      </Button>
    </form>
  );
};
