export enum ApiPath {
  // AUTH
  CHANGE_EMAIL = '/auth/patients/email',
  CHECK_EMAIL = '/auth/patients/email',
  CONFIRM_EMAIL = '/auth/confirm-email',
  CONFIRM_NEW_EMAIL = '/auth/patients/confirm-new-email',
  CONFIRM_PHONE_NUMBER = '/auth/patients/confirm-phone-number',
  FORGOT_PASSWORD = '/auth/forgot-password',
  RECAPTCHA_VERIFICATION = '/auth/verify-recaptcha',
  REGISTER = '/auth/patients/register',
  RESEND_REGISTRATION_EMAIL = '/auth/resend-registration-email',
  RESET_PASSWORD = '/auth/reset-password',
  SEND_PHONE_CONFIRMATION_CODE = '/auth/patients/send-phone-number-confirmation-code',

  CONFIRM_BOOKING = '/patients/confirm-booking',
  LOCATIONS = '/locations',
  PATIENT_ME = '/patients/me',
  PATIENT_RESULTS = 'patients/results',
}
