import React, { useCallback, useContext, useEffect } from 'react';
// import { Redirect, useLocation } from 'react-router-dom';
// import { Loader } from '../../components/ui/Loader/Loader';
// import { useDoctors } from '../../hooks/useDoctors';
// import { usePatient } from '../../hooks/usePatient';
import { store } from '../../store/store';
// import { PatientConfirmBookingDto } from '../../types/api-types';
// import { FindLocationBy } from '../../types/enums/find-location-by.enum';
// import { Path } from '../../types/enums/path.enum';
import { onBeforeUnloadListener } from '../../utils/events';
// import { logger } from '../../utils/logger';
// import { parseQueryParams } from '../../utils/parsers';
import { FlightChoicePage } from './FlightChoicePage';
// import { useConfirmBooking } from './hooks/useConfirmBooking';
// import { bookingCalendlyRedirectParamsSchema } from './validation';

export const FlightChoicePageContainer = () => {
  const { state } = useContext(store);
  // const location = useLocation();
  // const { value: redirectParams, error: redirectParamsValidationError } = parseQueryParams(location, bookingCalendlyRedirectParamsSchema, true);
  // const { phone_number: phoneNumber, email } = state.currentUser?.attributes!;
  //
  // const { isLoadingDoctors, fetchingDoctorError, doctorList } = useDoctors(FindLocationBy.CALENDLY_UUID, redirectParams.event_type_uuid);
  // const { isLoadingPatient, patient } = usePatient();
  // const confirmBooking = useConfirmBooking();

  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnloadListener);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnloadListener);
    };
  });

  // const onDataSubmit = useCallback(async (patientData: PatientConfirmBookingDto) => {
  //   //await confirmBooking({ patientData, redirectParams });
  //   return new Promise(1);
  // }, []);
  //

  // if (fetchingDoctorError) {
  //   logger.error(fetchingDoctorError);
  // }
  //
  // if (redirectParamsValidationError) {
  //   return <Redirect to={Path.HOME}/>;
  // }
  //
  // if (isLoadingPatient || isLoadingDoctors) {
  //   return <Loader fullscreen size='large' />;
  // }

  return (
    <FlightChoicePage
      // onDataSubmit={onDataSubmit}
      // doctorList={doctorList!}
      // calendlyRedirectParams={redirectParams}
      // patient={patient?.patient}
      // phoneNumber={phoneNumber}
      // email={email}
    />
  );
};
