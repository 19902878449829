import { FormEvent } from 'react';

interface InputHandlerArgs {
  maxLength?: number;
  onlyCharacters?: boolean;
}

export const handleInputFormat: (
  args?: InputHandlerArgs
) => (e: FormEvent<HTMLInputElement>) => void = ({
  maxLength,
  onlyCharacters,
} = {}) => ({ target }: { target: any }) => {
  // remove space on the beginning
  target.value = target.value.replace(/^[\s]+$/g, '');

  if (maxLength && target.value.length > maxLength) {
    target.value = target.value.slice(0, maxLength);
  }

  if (onlyCharacters) {
    target.value = target.value.replace(/[^A-Za-z\s]/gi, '');
  }
};
