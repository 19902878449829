import Joi from '@hapi/joi';
import { emailSchema, passwordSchema, setCustomValidationError } from '../../../utils/validation';

export interface ChangeEmailInputsSchema {
  currentPassword: string;
  newEmail: string;
}

export const changeEmailInputSchema = (currentEmail: string) =>
  Joi.object<ChangeEmailInputsSchema>({
    currentPassword: passwordSchema,
    newEmail: emailSchema
      .invalid(currentEmail)
      .required()
      .error(setCustomValidationError('any.invalid', 'email.different'))
  });
