import React, { FC, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Loader } from '../../components/ui/Loader/Loader';
import { store } from '../../store/store';
import { StoreActionType } from '../../store/store-types';
import { HomePageDialogState } from '../../types/enums/home-page-dialog-state.enum';
import { Path } from '../../types/enums/path.enum';
import { showHomePageDialog } from '../../utils/home-page-dialog-state-handlers';
import { parseQueryParams } from '../../utils/parsers';
import { emailRedirectParamsSchema } from '../../utils/validation';

export const PasswordResetPage: FC = () => {
  const location = useLocation();
  const { error: queryParamsValidationError, value: queryParams } = parseQueryParams(location, emailRedirectParamsSchema);
  const { push } = useHistory();
  const { dispatch } = useContext(store);

  useEffect(() => {
    if (queryParamsValidationError) {
      return push(Path.HOME);
    }

    push(Path.HOME);
    dispatch({ type: StoreActionType.SET_RESET_PASSWORD_DATA, payload: queryParams });
    showHomePageDialog(dispatch, HomePageDialogState.resetPassword);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loader fullscreen size='large' />;
};
