import Joi from '@hapi/joi';
import { passwordSchema } from '../../../../../utils/validation';
import { PasswordInputSchema } from '../validation';

export interface RegisterPasswordInputsSchemaInterface
  extends PasswordInputSchema {
  agreement: boolean;
}

export const registerPasswordInputsSchema = Joi.object<
  RegisterPasswordInputsSchemaInterface
>({
  agreement: Joi.boolean().valid(true).required(),
  password: passwordSchema,
});
