import React, { useCallback, useContext } from 'react';
import { showErrorNotification } from '../../../components/ui/Notifications/Notifications';
import { store } from '../../../store/store';
import { BusinessErrorCode } from '../../../types/enums/business-error-code.enum';
import { CognitoErrorCode } from '../../../types/enums/cognito-error-code.enum';
import { signIn } from '../../../utils/auth';
import { logger } from '../../../utils/logger';
import { ChangeEmailForm } from './ChangeEmailForm';
import { useChangeEmail } from './hooks/useChangeEmail';
import { ChangeEmailInputsSchema } from './validation';

export const ChangeEmailFormContainer = () => {
  const { state, dispatch } = useContext(store);
  const changeEmail = useChangeEmail();
  const onDataSubmit = useCallback(async ({ newEmail, currentPassword }: ChangeEmailInputsSchema) => {
    try {
      await signIn(dispatch, { email: state.currentUser?.attributes.email!, password: currentPassword });
      await changeEmail({ email: newEmail });
    } catch (error) {
      if (error.code === CognitoErrorCode.NotAuthorizedException) {
        error.errorCode = BusinessErrorCode.NO_ACCESS_ERROR;
      }

      logger.error(error);
      showErrorNotification(error, { config: { [BusinessErrorCode.NO_ACCESS_ERROR]: 'invalidCredentials' }});
    }
  }, [dispatch, state, changeEmail]);

  return <ChangeEmailForm onDataSubmit={onDataSubmit} email={state.currentUser?.attributes.email!}/>;
};
