import React from 'react';
import ReactDOM from 'react-dom';
import { ReactQueryConfigProvider, ReactQueryProviderConfig } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import './bootstrap/i18n';
import { AppContainer } from './components/App/AppContainer';
import './index.less';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { StateProvider } from './store/store';

const { REACT_APP_INSTANCE_NAME } = process.env;
// @ts-ignore
window.env = REACT_APP_INSTANCE_NAME;

const queryConfig: ReactQueryProviderConfig = { queries: { refetchOnWindowFocus: false, retry: false } };

ReactDOM.render((
  <ReactQueryConfigProvider config={queryConfig}>
    <StateProvider>
      <BrowserRouter>
        <AppContainer/>
      </BrowserRouter>
    </StateProvider>
  </ReactQueryConfigProvider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
