import React, { FC, useContext, useState } from 'react';
import { showErrorNotification, showInfoNotification } from '../../../../../components/ui/Notifications/Notifications';
import { MAX_RESEND_COUNT, MAX_RESEND_ERROR_MESSAGE } from '../../../../../constants/fetch';
import { store } from '../../../../../store/store';
import { ApiPath } from '../../../../../types/enums/api-path.enum';
import { BusinessErrorCode } from '../../../../../types/enums/business-error-code.enum';
import { Platform } from '../../../../../types/enums/platform.enum';
import { RecaptchaActionType } from '../../../../../types/enums/recaptcha-action-type.enum';
import { verifyRecaptcha } from '../../../../../utils/auth';
import { Fetch } from '../../../../../utils/fetch';
import { logger } from '../../../../../utils/logger';
import { ResetPasswordEmailSent } from './ResetPasswordEmailSent';

export interface Props {
  email: string;
}

export const ResetPasswordEmailSentContainer: FC<Props> = ({ email }) => {
  const { state } = useContext(store);
  const [isResendingEmail, setIsResendingEmail] = useState(false);
  const [resendCount, setResendCount] = useState(0);

  const resendEmail = async () => {
    if (resendCount > MAX_RESEND_COUNT) {
      // TODO should that error notification be displayed ?
      showErrorNotification({ message: MAX_RESEND_ERROR_MESSAGE, errorCode: BusinessErrorCode.MAX_REQUESTS_ERROR }, {
        defaultNotification: 'maxRequests',
      });

      return logger.error(MAX_RESEND_ERROR_MESSAGE);
    }

    try {
      setResendCount(resendCount + 1);
      setIsResendingEmail(true);
      await verifyRecaptcha(RecaptchaActionType.RESET_PASSWORD, { email });
      await Fetch.post(ApiPath.FORGOT_PASSWORD, { email, platform: Platform.BOOKING_WEB }, {
        params: { language: state.language },
      });
      showInfoNotification('resetPasswordEmailSent');
    } catch (error) {
      logger.error(error);
      showErrorNotification(error);
    } finally {
      setIsResendingEmail(false);
    }
  };

  return <ResetPasswordEmailSent email={email} resendEmail={resendEmail} isResendingEmail={isResendingEmail} />;
};
