import { joiResolver } from '@hookform/resolvers';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../../../components/ui/Button/Button';
import { Icon } from '../../../../../components/ui/Icon/Icon';
import { Input } from '../../../../../components/ui/Input/Input';
import { PHONE_VERIFICATION_CODE_LENGTH } from '../../../../../constants/common';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { MainLocales } from '../../../../../locales/locales.interfaces';
import { PatientOtpDto, SuccessResponse } from '../../../../../types/api-types';
import dialogsStyle from '../Dialogs.module.scss';
import style from './OtpConfirmation.module.scss';
import { confirmationInputsSchema } from './validation';

interface Props {
  handleBackIconClick: () => void;
  isResendingCode: boolean;
  onOTPSubmit: ({ otp }: { otp: string }) => Promise<void>;
  onResend: () => Promise<SuccessResponse>;
  phoneNumber: string;
}

export const OtpConfirmation: FC<Props> = ({ onOTPSubmit, onResend, handleBackIconClick, phoneNumber, isResendingCode }) => {
  const { t } = useTranslation<MainLocales>('mainLocales');
  const { control, handleSubmit, formState } = useForm<PatientOtpDto>({
    mode: 'onChange',
    resolver: joiResolver(confirmationInputsSchema),
  });

  return (
    <div className={dialogsStyle.content}>
      <div className={dialogsStyle.left}>
        <Icon alt='<-' type='ic-back' onClick={handleBackIconClick} marginBottom />
        <h1>{t('otpConfirmationHeader')}</h1>
        <p>{phoneNumber}</p>
        <p className={style.otpLabel}>{t('confirmationOtpLabel')}</p>
        <form onSubmit={handleSubmit(onOTPSubmit)}>
          <Input
            control={control}
            name={'otp' as keyof PatientOtpDto}
            error={null}
            maxLength={PHONE_VERIFICATION_CODE_LENGTH}
            className={style.confirmationField}
            onlyNumbers
            required
            autoFocus
          />
          <Button
            type='primary'
            htmlType='submit'
            disabled={!formState.isValid || formState.isSubmitting || !formState.isDirty}>
            {t('next')}
          </Button>
        </form>
        <div className={style.resendContainer}>
          <span className={style.resendInfo}>{t('otpNotDeliveredInfo')}</span>
          <Button type='link' onClick={onResend} disabled={isResendingCode}>{t('resend')}</Button>
        </div>
      </div>
    </div>
  );
};
