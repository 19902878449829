import { PhoneNumberUtil } from 'google-libphonenumber';

const COUNTRY_CODES: number[] = PhoneNumberUtil.getInstance()
  .getSupportedRegions()
  .map(
    (region) => +PhoneNumberUtil.getInstance().getCountryCodeForRegion(region)
  );

export const PHONE_NUMBER_COUNTRY_CODES: number[] = [
  ...new Set(COUNTRY_CODES),
].sort((a, b) => a - b);
