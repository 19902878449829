import React, { FC } from 'react';
import { Button } from '../../components/ui/Button/Button';
import { useTranslation } from '../../hooks/useTranslation';
import { MainLocales } from '../../locales/locales.interfaces';
import style from './HomePage.module.scss';

interface Props {
  bookingViewAsset: 'calendly-landing' | 'calendly-landing-tablet';
  handleBookingClick: () => void;
}

export const HomePage: FC<Props> = ({ bookingViewAsset, handleBookingClick }) => {
  const { t } = useTranslation<MainLocales>('mainLocales');

  return (
    <div className={style.home}>
      <div className={style.left}>
        <h1>{t('homeTitle')}</h1>
        <p>{t('homeSubtitle')}</p>
        <div className={style.bookButtonContainer}>
          <Button type='primary' onClick={handleBookingClick}>{t('homePageBookButton')}</Button>
        </div>
      </div>
      <div className={style.right}>
        <img
          alt='Booking view'
          srcSet={`
          /assets/${bookingViewAsset}@3x.jpg 3x,
          /assets/${bookingViewAsset}@2x.jpg 2x
          `}
          src={`/assets/${bookingViewAsset}.jpg`}
          onClick={handleBookingClick}
        />
      </div>
    </div>
  );
};
