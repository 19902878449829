import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import { store } from '../../store/store';
import breakpoints from '../../styles/breakpoints.scss';
import { HomePageDialogState } from '../../types/enums/home-page-dialog-state.enum';
import { Path } from '../../types/enums/path.enum';
import { hideHomePageDialog, showHomePageDialog } from '../../utils/home-page-dialog-state-handlers';
import { HomePage } from './HomePage';
import { HomePageDialog } from './HomePageDialog/HomePageDialog';
import { useShouldVerifyAccount } from './hooks/useShouldVerifyAccount';

export const HomePageContainer = () => {
  const { push } = useHistory();
  const { dispatch, state } = useContext(store);
  const windowWidth = useWindowWidth();
  useShouldVerifyAccount();

  const bookingViewAsset = (() => {
    switch (true) {
      case (windowWidth >= parseInt(breakpoints.laptopXS)): {
        return 'calendly-landing';
      }
      default:
        return 'calendly-landing-tablet';
    }
  })();

  const handleBookingClick = () => {
    if (state.currentUser) {
      push(Path.BOOKING);
    } else {
      showHomePageDialog(dispatch, HomePageDialogState.loginEmailBookingFlow);
    }
  };

  const handleHomeDialogClose = () => hideHomePageDialog(dispatch);

  return (
    <>
      <HomePage bookingViewAsset={bookingViewAsset} handleBookingClick={handleBookingClick} />
      <HomePageDialog handleDialogClose={handleHomeDialogClose} showDialog={state.showHomePageDialog}/>
    </>
  );
};
