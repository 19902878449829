import { joiResolver } from '@hookform/resolvers';
import cx from 'classnames';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../../../components/ui/Button/Button';
import { Input } from '../../../../../components/ui/Input/Input';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { MainLocales } from '../../../../../locales/locales.interfaces';
import dialogsStyle from '../Dialogs.module.scss';
import style from './PasswordReset.module.scss';
import { resetPasswordInputSchema, ResetPasswordInputsSchema } from './validation';

interface PasswordResetProps {
  email: string;
  onDataSubmit: (data: { password: string, repeatPassword: string }) => void;
}

export const PasswordReset: FC<PasswordResetProps> = ({ email, onDataSubmit }) => {
  const { t } = useTranslation<MainLocales>('mainLocales');
  const { control, handleSubmit, errors, formState } = useForm<ResetPasswordInputsSchema>({
    resolver: joiResolver(resetPasswordInputSchema),
  });

  return (
    <div className={dialogsStyle.content}>
      <div className={cx(dialogsStyle.left, style.fullWidth)}>
        <h1>{t('resetPasswordHeader')}</h1>
        <p>{email}</p>
        <form onSubmit={handleSubmit(onDataSubmit)}>
          <div className={style.inputsContainer}>
            <Input
              name='password'
              control={control}
              defaultValue=''
              label={t('newPassword')}
              error={errors.password!}
              required
              type='password'
            />
            <Input
              name='repeatPassword'
              control={control}
              defaultValue=''
              label={t('repeatPassword')}
              error={errors.repeatPassword ? { type: 'password.same' } : null}
              required
              type='password'
              iconRender={() => null}
            />
          </div>
          <Button
            type='primary'
            htmlType='submit'
            disabled={!formState.isDirty || formState.isSubmitting}>
            {t('changePassword')}
          </Button>
        </form>
      </div>
    </div>
  );
};
