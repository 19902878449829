import Joi from '@hapi/joi';
import { passwordSchema, setCustomValidationError } from '../../../utils/validation';

export interface ChangePasswordInputsSchema {
  currentPassword: string;
  newPassword: string;
  newPasswordRepeat: string;
}

export const changePasswordInputSchema = Joi.object<ChangePasswordInputsSchema>(
  {
    currentPassword: passwordSchema,
    newPassword: passwordSchema
      .invalid(Joi.ref('currentPassword'))
      .error(setCustomValidationError('any.invalid', 'password.different')),
    newPasswordRepeat: Joi.any().valid(Joi.ref('newPassword')).required()
      .error(setCustomValidationError('any.only', 'password.same')),
  }
);
