import { useContext } from 'react';
import { useQuery } from 'react-query';
import { store } from '../../../store/store';
import { PatientResult } from '../../../types/api-types';
import { ApiPath } from '../../../types/enums/api-path.enum';
import { ApiQueries } from '../../../types/enums/api-queries.enum';
import { getUserToken } from '../../../utils/auth';
import { Fetch, FetchErrorResponse } from '../../../utils/fetch';

export const usePatientResults = () => {
  const { state } = useContext(store);
  const { data, isLoading, error } = useQuery<PatientResult[], ApiQueries, FetchErrorResponse>(ApiQueries.PATIENT_RESULT, async () => {
    const { data: responseData } = await Fetch.get<PatientResult[]>(
      ApiPath.PATIENT_RESULTS,
      { headers: { Authorization: await getUserToken(state) } }
    );

    return responseData;
  });

  return {
    results: data,
    isLoadingResults: isLoading,
    fetchingResultsError: error,
  };
};
