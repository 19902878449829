import { joiResolver } from '@hookform/resolvers';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../../../components/ui/Button/Button';
import { Input } from '../../../../../components/ui/Input/Input';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { MainLocales } from '../../../../../locales/locales.interfaces';
import dialogsStyle from '../Dialogs.module.scss';
import { emailInputSchema, EmailInputSchema } from '../validation';
import style from './LoginEmail.module.scss';

interface LoginProps {
  defaultEmail?: string;
  isBookingFlow: boolean;
  onDataSubmit: ({ email }: { email: string }) => void;
}

export const LoginEmail: FC<LoginProps> = ({ isBookingFlow = false, onDataSubmit, defaultEmail }) => {
  const { t } = useTranslation<MainLocales>('mainLocales');
  const { control, handleSubmit, formState, errors } = useForm<EmailInputSchema>({
    resolver: joiResolver(emailInputSchema),
  });

  return (
    <div className={dialogsStyle.content}>
      <div className={dialogsStyle.left}>
        <h1>
          {
            isBookingFlow
              ? t('loginEmailHeaderStartBooking')
              : t('loginEmailHeaderYourAccount')
          }
        </h1>
        <p className={style.phoneNumberLabel}>
          {
            t('startBookingEnterEmail').split('\n').map(item => <span key={item}>{item}<br/></span>)
          }
        </p>
        <form className={style.form} onSubmit={handleSubmit(onDataSubmit)}>
          <div className={style.fullWidthFormContainer}>
            <Input
              name='email'
              control={control}
              defaultValue={defaultEmail}
              label={t('email')}
              error={errors.email!}
              autoFocus
            />
          </div>
          <Button
            type='primary'
            htmlType='submit'
            disabled={formState.isSubmitting}
          >
            {t('next')}
          </Button>
        </form>
      </div>
    </div>
  );
};
