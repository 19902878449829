import React, { FC, JSXElementConstructor, useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { store } from '../../store/store';
import { Path } from '../../types/enums/path.enum';
import { UserVerificationState } from '../../types/enums/user-verification-state.enum';
import { getUserVerificationState, isUserSessionValid } from '../../utils/auth';

interface Props extends RouteProps {
  component: JSXElementConstructor<any>;
}

export const PrivateRoute: FC<Props> = ({ component: Component, ...rest }) => {
  const { state } = useContext(store);
  const isUserLogged = isUserSessionValid();
  const isUserVerified = getUserVerificationState(state.currentUser!) === UserVerificationState.VERIFIED;
  const getBaseComponent = (props: any) => <Component {...props} />;
  const getRedirectComponent = (props: any) => <Redirect to={{ pathname: Path.HOME, state: { from: props.location } }}/>;

  return <Route {...rest} render={(isUserLogged && isUserVerified) ? getBaseComponent : getRedirectComponent}/>;
};
