import React, { useContext } from 'react';
import { Loader } from '../../components/ui/Loader/Loader';
import { useDoctors } from '../../hooks/useDoctors';
import { usePatient } from '../../hooks/usePatient';
import { store } from '../../store/store';
import { FindLocationBy } from '../../types/enums/find-location-by.enum';
import { RequestFormDetailsPage } from './RequestFormDetailsPage';

export const RequestFormDetailsPageContainer = () => {
  const { state } = useContext(store);
  // const { isLoadingPatient, patient } = usePatient();
  // const { isLoadingDoctors, doctorList } = useDoctors(FindLocationBy.ID, patient?.booking.locationId);
  // const { phone_number: phoneNumber, email } = state.currentUser?.attributes!;

  // if (isLoadingPatient || isLoadingDoctors) {
  //   return <Loader fullscreen size='large' />;
  // }

  return (
    <RequestFormDetailsPage
      // patient={patient?.patient}
      // phoneNumber={phoneNumber!}
      // email={email}
      // doctorList={doctorList!}
      // doctorId={patient?.patient.doctorId}
    />
  );
};
