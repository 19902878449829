import { Auth } from 'aws-amplify';
import { showErrorNotification, showInfoNotification } from '../components/ui/Notifications/Notifications';
import { NOT_AUTHORIZED_MESSAGE } from '../constants/auth';
import { AppContext, CurrentUser, Dispatch, StoreActionType, StoreState } from '../store/store-types';
import { AuthRecaptchaVerificationDto, PatientAuthRegisterDto, SuccessResponse } from '../types/api-types';
import { ApiPath } from '../types/enums/api-path.enum';
import { BusinessErrorCode } from '../types/enums/business-error-code.enum';
import { CognitoErrorCode } from '../types/enums/cognito-error-code.enum';
import { RecaptchaActionType } from '../types/enums/recaptcha-action-type.enum';
import { UserRole } from '../types/enums/user-role.enum';
import { UserVerificationState } from '../types/enums/user-verification-state.enum';
import { RecaptchaPayloadData } from '../types/interfaces/recaptcha-payload-data.interface';
import { Fetch } from './fetch';
import { logger } from './logger';

const { REACT_APP_AWS_COGNITO_RECAPTCHA_TOKEN } = process.env;

export const getUserVerificationState = (currentUser: CurrentUser): UserVerificationState => {
  if (!currentUser) {
    logger.error(NOT_AUTHORIZED_MESSAGE);

    return UserVerificationState.NOT_EXISTS;
  }

  if (!currentUser.attributes.email_verified) {
    return UserVerificationState.EMAIL_NOT_VERIFIED;
  }

  if (!currentUser.attributes.phone_number_verified) {
    return UserVerificationState.PHONE_NUMBER_NOT_VERIFIED;
  }

  return UserVerificationState.VERIFIED;
};

export const verifyRecaptcha = async (
  action: RecaptchaActionType,
  data: RecaptchaPayloadData,
) => {
  // tslint:disable-next-line:await-promise
  const recaptchaToken = await grecaptcha.execute(
    REACT_APP_AWS_COGNITO_RECAPTCHA_TOKEN,
    { action },
  );
  await Fetch.post<SuccessResponse>(ApiPath.RECAPTCHA_VERIFICATION, {
    recaptchaToken,
    ...data,
  } as AuthRecaptchaVerificationDto & RecaptchaPayloadData);
};

export const signOut = ({ state, dispatch }: AppContext) => {
  state.currentUser?.signOut(); // clear amplify local storage
  dispatch({ type: StoreActionType.SET_CURRENT_USER, payload: null }); // clear current session state user
};

export const signIn = async (
  dispatch: Dispatch,
  { email, password }: PatientAuthRegisterDto,
): Promise<CurrentUser> => {
  const cognitoUser: CurrentUser = await Auth.signIn({
    username: email,
    password,
  });

  if (!cognitoUser.attributes['custom:role'] || cognitoUser.attributes['custom:role'] !== UserRole.Patient) {
    cognitoUser.signOut();
  }

  dispatch({ type: StoreActionType.SET_CURRENT_USER, payload: cognitoUser });

  return cognitoUser;
};

export const refreshCurrentUser = async (dispatch: Dispatch, updateAppState: boolean = true): Promise<CurrentUser> => {
  const currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
  updateAppState && dispatch({ type: StoreActionType.SET_CURRENT_USER, payload: currentUser });

  return currentUser;
};

export const isUserSessionValid = async (): Promise<boolean> => {
  const session = await Auth.currentSession();

  return session.isValid();
};

export const changePassword = async (
  currentUser: CurrentUser,
  currentPassword: string,
  newPassword: string,
): Promise<void> => {
  return currentUser?.changePassword(
    currentPassword,
    newPassword,
    (err: any) => {
      if (err) {
        if (err.code === CognitoErrorCode.NotAuthorizedException) {
          err.errorCode = BusinessErrorCode.NO_ACCESS_ERROR;
        }

        logger.error(err);
        showErrorNotification(err, { config: { [BusinessErrorCode.NO_ACCESS_ERROR]: 'invalidCredentials' } });
      } else {
        showInfoNotification('passwordChanged');
      }
    },
  );
};

export const getUserToken = async (state: StoreState, withBearerPrefix: boolean = true): Promise<string> => {
  if (!state.currentUser) {
    logger.error(NOT_AUTHORIZED_MESSAGE);
    throw new Error(NOT_AUTHORIZED_MESSAGE);
  }

  const prefix = 'Bearer ';
  const session = await Auth.currentSession();
  const token = session
    .getAccessToken()
    .getJwtToken();

  return withBearerPrefix ? (prefix + token) : token;
};
