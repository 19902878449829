import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { ErrorResponse } from '../types/interfaces/error-response.interface';

export declare type FetchErrorResponse = AxiosError<ErrorResponse>;

const { REACT_APP_API_URL } = process.env;
const fetchConfig: AxiosRequestConfig = {
  baseURL: REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};

export const Fetch: AxiosInstance = axios.create(fetchConfig);
