import React, { FC, ReactNode, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from '../../hooks/useTranslation';
import { LayoutLocales } from '../../locales/locales.interfaces';
import { store } from '../../store/store';
import { HomePageDialogState } from '../../types/enums/home-page-dialog-state.enum';
import { Path } from '../../types/enums/path.enum';
import { showHomePageDialog } from '../../utils/home-page-dialog-state-handlers';
import { Button } from '../ui/Button/Button';
import style from './Layout.module.scss';
import { NavMenu } from './NavMenu/NavMenu';

interface Props {
  children: ReactNode;
}

export const Layout: FC<Props> = ({ children }) => {
  const { t } = useTranslation<LayoutLocales>('layoutLocales');
  const { dispatch, state } = useContext(store);
  const { push } = useHistory();

  const NavLinks = () => (
    <>
      <Link to={Path.TERMS}>{t('terms')}</Link>
      <Link to={Path.PRIVACY_POLICY}>{t('privacyPolicy')}</Link>
    </>
  );

  const handleLoginButton = () => {
    push(Path.HOME);
    showHomePageDialog(dispatch, HomePageDialogState.loginEmail);
  };

  return (
    <div className={style.container}>
      <div className={style.content}>
        <div className={style.nav}>
          <Link to={Path.HOME}>
            <img alt='Logo' src='/assets/logo.svg'/>
            <span className={style.homeLink}>Cerdio</span>
          </Link>
          <div className={style.rightSide}>
            <NavLinks/>
          </div>
        </div>
        {children}
      </div>
      <div className={style.bottomNavMobile}>
        <NavLinks/>
      </div>
      <div className={style.backgroundContainer}>
        <div className={style.backgroundBlue}/>
        <div className={style.backgroundGreyLine}/>
      </div>
    </div>
  );
};
