import React, { FC } from 'react';
import { BookingForm } from '../../components/BookingForm/BookingForm';
import { Doctor, Patient } from '../../types/api-types';

interface Props {
  // doctorId?: string;
  // doctorList: Doctor[];
  // email: string;
  // patient?: Patient;
  // phoneNumber: string;
}

export const RequestFormDetailsPage: FC<Props> = ({ }) => {
  return (
    <BookingForm
      // patient={patient}
      // phoneNumber={phoneNumber}
      // email={email}
      // doctorList={doctorList}
      // defaultDoctorId={doctorId}
      // readonly
    />
  );
};

