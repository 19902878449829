import { Button as AntButton } from 'antd';
import { ButtonProps as AntdButtonProps } from 'antd/lib/button';
import cx from 'classnames';
import React, { FC } from 'react';
import style from './Button.module.scss';

export interface ButtonProps extends Omit<AntdButtonProps, 'loading' | 'style'> {
  secondary?: boolean;
}

export const Button: FC<ButtonProps> = ({ children, className, secondary, ...props }) => {
  const classNames = cx({
    [style.button]: true,
    [style.buttonSecondary]: secondary,
    [style.link]: props.type === 'link' && !props.danger,
  }, className);

  return (
    <AntButton
      className={classNames}
      {...props}
    >
      {children}
    </AntButton>
  );
};
