import { CognitoUser } from 'amazon-cognito-identity-js';
import { HomePageDialogState } from '../types/enums/home-page-dialog-state.enum';
import { Language } from '../types/enums/language.enum';
import { PaymentStatus } from '../types/enums/payment-status.enum';
import { UserRole } from '../types/enums/user-role.enum';
import { EmailRedirectParamsSchema } from '../utils/validation';

interface CurrentUserAttributes {
  'custom:role': UserRole;
  email: string;
  email_verified: string;
  phone_number: string;
  phone_number_verified: string;
  sub: string;
}

export declare type CurrentUser = CognitoUser & {
  attributes: CurrentUserAttributes;
};

export declare type Named = {
  name: string,
};

export declare type Spot = {
  airport: Named,
  scheduledTimeLocal: string,
};

export declare type Flight = {
  airline: Named,
  arrival: Spot,
  departure: Spot,
  number: string
};

export interface StoreState {
  confirmedEmail?: string;
  currentUser?: CurrentUser;
  currentUserReady: boolean;
  flight: Flight | null;
  homePageDialogState: HomePageDialogState | null;
  language: Language;
  paymentStatus: PaymentStatus | null;
  resetPasswordData: EmailRedirectParamsSchema | null;
  showHomePageDialog: boolean;
}

export interface DispatchData {
  payload: any;
  type: StoreActionType;
}

export type Dispatch = (data: DispatchData) => any;

export interface AppContext {
  dispatch: Dispatch;
  state: StoreState;
}

export enum StoreActionType {
  SET_CURRENT_USER = 'SET_CURRENT_USER',
  SET_HOME_PAGE_DIALOG_STATE = 'SET_HOME_PAGE_DIALOG_STATE',
  SET_RESET_PASSWORD_DATA = 'SET_RESET_PASSWORD_DATA',
  SET_LANGUAGE = 'SET_LANGUAGE',
  SET_PAYMENT_STATUS = 'SET_PAYMENT_STATUS',
  SET_SHOW_HOME_PAGE_DIALOG = 'SET_SHOW_HOME_PAGE_DIALOG',
  SET_CONFIRMED_EMAIL = 'SET_CONFIRMED_EMAIL',
  SET_FLIGHT = 'SET_FLIGHT',
}

export interface StoreAction {
  payload: any;
  type: StoreActionType;
}
