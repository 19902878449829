import { joiResolver } from '@hookform/resolvers';
import cx from 'classnames';
import moment from 'moment';
import React, {FC, useCallback, useContext, useState} from 'react';
import { useForm } from 'react-hook-form';
import { MAX_DOCUMENT_LENGTH, MAX_POSTAL_CODE_LENGTH, MAX_VEHICLE_REGISTRATION_PLATE_LENGTH, SUPPORT_EMAIL } from '../../constants/common';
import { useTranslation } from '../../hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { getCountryNames } from '../../locales/countries';
import { MainLocales } from '../../locales/locales.interfaces';
import { CalendlyRedirectParams } from '../../routes/BookingCalendlyRedirectPage/interfaces/calendly-redirect-params.interface';
import { flightChoiceInputsSchema, FlightChoiceInputsSchema } from '../../routes/FlightChoicePage/validation';
import { store } from '../../store/store';
import {StoreActionType} from '../../store/store-types';
import {Doctor, Patient, PatientConfirmBookingDto} from '../../types/api-types';
import { DocumentType } from '../../types/enums/document-type.enum';
import { Gender } from '../../types/enums/gender.enum';
import {Path} from '../../types/enums/path.enum';
import {PaymentStatus} from '../../types/enums/payment-status.enum';
import { SendResultsTo } from '../../types/enums/send-results-to.enum';
import { AutoComplete } from '../ui/AutoComplete/AutoComplete';
import { Button } from '../ui/Button/Button';
import { Checkbox } from '../ui/Checkbox/Checkbox';
import { DatePicker2 } from '../ui/DatePicker/DatePicker';
import { Input } from '../ui/Input/Input';
import { PhoneNumberInput } from '../ui/PhoneNumberInput/PhoneNumberInput';
import { Radio, RadioGroup } from '../ui/RadioGroup/RadioGroup';
import { Option, Select } from '../ui/Select/Select';
import style from './FlightSelectForm.module.scss';

// TODO use API configuration instead
const { REACT_APP_RESULTS_PAGE } = process.env;
const showResultsPage = REACT_APP_RESULTS_PAGE?.toString() === 'true';

interface Props {
  // calendlyRedirectParams?: CalendlyRedirectParams;
  // defaultDoctorId?: string;
  // doctorList: Doctor[];
  // email: string;
  // onDataSubmit?: (data: any) => Promise<void>;
  // patient?: Patient;
  // phoneNumber: string;
  // readonly?: boolean;
}

export const FlightSelectForm: FC<Props> = props => {
  const { push } = useHistory();
  const { state, dispatch } = useContext(store);
  const { flight } = state;
  const { t } = useTranslation<MainLocales>('mainLocales');
  const { control, handleSubmit, errors, formState, watch, getValues, setValue, clearErrors } = useForm<FlightChoiceInputsSchema>({
    resolver: joiResolver(flightChoiceInputsSchema),
  });
  const { } = props;
  const { flightNumber } = getValues();
  const watchFlightNumber = watch("flightNumber"); // you can supply default value as second argument
  const watchDepartureDate = watch("departureDate"); // you can supply default value as second argument
  //const [flight, setFlight] = useState(null);
  const [lastSearch, setLastSearch] = useState(null);

  // const onDataSubmit = useCallback(async (patientData: PatientConfirmBookingDto) => {
  //   console.log(values);
  //   push(Path.BOOKING_CALENDLY_REDIRECT);
  // }, []);

  const onSubmit = values => {
    console.log(values);
    push(Path.REQUEST_FORM_DETAILS);
  };

  //https://aerodatabox.p.rapidapi.com/flights/number/BA627/2020-09-13
  const reqAerobox = (urlPart:string) => {
    fetch(`https://aerodatabox.p.rapidapi.com/flights${urlPart}`, {
      "method": "GET",
      "headers": {
        "x-rapidapi-host": "aerodatabox.p.rapidapi.com",
        "x-rapidapi-key": "8f8f8e3a40msh96d702bc022ee17p1b976cjsn968ee49efb36"
      }
    })
      .then(response => {
      response.json().then((json) => {
        console.log(json);
        dispatch({
          type: StoreActionType.SET_FLIGHT,
          payload: json[0],
        });
      });
    })
      .catch(err => {
      console.log(err);
    });
  }

  if (flight) {
    console.log(flight);
    console.log(flight.airline.name);
    console.log(flight.departure.airport.name);
    console.log(flight.arrival.scheduledTimeLocal);
  }

  // if (watchFlightNumber && watchFlightNumber.length > 2 && watchFlightNumber !== lastSearch) {
  //   setLastSearch(watchFlightNumber);
  //   console.log("Watch", watchFlightNumber);
  //   reqAerobox(`/number/${watchFlightNumber}.toUpperCase()}/${fromDate}/`);
  // }

  const doSearch = () => {
    const date = moment(watchDepartureDate).format("YYYY-MM-DD");
    console.log(watchFlightNumber, date);
    reqAerobox(`/number/${watchFlightNumber.toUpperCase()}/${date}/`);
  }

  // console.log("render", flightNumber);
  //
  // updateFlightList();

  console.log('Errors', errors);

  return (
    <div className={style.content}>
      <h1 className={style.pageHeader}>Singapore Changi Airport Testing</h1>
      <p>According to current Singapore Government policy you must book a Covid-19 test for arrival at Changi Airport.</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.row}>
          <div className={style.column}>
            <Input
              control={control}
              name='flightNumber'
              label={'Please enter your flight number'}
              className={style.field}
              error={errors.flightNumber!}
              onChange={(event) => { console.log("Event", event); }}
              required
            />
          </div>
          <div className={style.column}>
            <DatePicker2
              control={control}
              name='departureDate'
              format={'DD.MM.YYYY'}
              label='... and departure date'
              className={style.field}
              error={errors.departureDate!}
              required
            />
          </div>
          <div className={style.column}>
            <Button
              type='primary'
              htmlType='button'
              className={style.submitButton}
              disabled={formState.isSubmitting}
              onClick={doSearch}
            >
              {"Search"}
            </Button>
          </div>
          {flight && (
            <div>
              <hr/>
            <div className={style.row}>
              <div className={style.column} style={{justifyContent: 'right'}}>
                <h5>Departs</h5>
                <h3>{flight.departure.airport.name}</h3>
              </div>
              <div className={style.column}>
                <h2>{flight.airline.name} {flight.number}</h2>
                <Button
                  type='primary'
                  htmlType='submit'
                  className={style.submitButton}
                  disabled={formState.isSubmitting}
                >
                  {"Book test"}
                </Button>
              </div>
              <div className={style.column}>
                <h5>Arrives</h5>
                <h3>{flight.arrival.airport.name}</h3>
                <div>{moment(flight.arrival.scheduledTimeLocal).format("ddd DD.MM.YY HH:MM")}</div>
              </div >
            </div>
          </div>)}
          {/*<div className={style.column}>*/}
          {/*  <Input*/}
          {/*    control={control}*/}
          {/*    name='flightAirline'*/}
          {/*    // label={'Please enter your flight number'}*/}
          {/*    className={style.field}*/}
          {/*    error={errors.flightAirline!}*/}
          {/*    required*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<div className={style.column}>*/}
          {/*  <Input*/}
          {/*    control={control}*/}
          {/*    name='flightOrigin'*/}
          {/*    label={'from:'}*/}
          {/*    className={style.field}*/}
          {/*    error={errors.flightOrigin!}*/}
          {/*    required*/}
          {/*  />*/}
          {/*</div>*/}
        </div>

      </form>
    </div>
  );
};
