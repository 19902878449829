import { joiResolver } from '@hookform/resolvers';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../../../components/ui/Button/Button';
import { Icon } from '../../../../../components/ui/Icon/Icon';
import { Input } from '../../../../../components/ui/Input/Input';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { MainLocales } from '../../../../../locales/locales.interfaces';
import dialogsStyle from '../Dialogs.module.scss';
import { emailInputSchema, EmailInputSchema } from '../validation';
import style from './ForgotPassword.module.scss';

interface ForgotPasswordProps {
  email: string;
  handleBackIconClick: () => void;
  onDataSubmit: ({ email }: { email: string }) => void;
}

export const ForgotPassword: FC<ForgotPasswordProps> = ({ email, onDataSubmit, handleBackIconClick }) => {
  const { t } = useTranslation<MainLocales>('mainLocales');
  const { control, handleSubmit, formState, errors } = useForm<EmailInputSchema>({
    resolver: joiResolver(emailInputSchema),
  });

  return (
    <div className={dialogsStyle.content}>
      <div className={dialogsStyle.left}>
        <Icon alt='<-' type='ic-back' onClick={handleBackIconClick} marginBottom />
        <h1>{t('forgotPasswordHeader')}</h1>
        <p>{t('forgotPasswordEnterEmail')}</p>
        <form onSubmit={handleSubmit(onDataSubmit)}>
          <div className={style.fullWidthFormContainer}>
            <Input
              name='email'
              control={control}
              defaultValue={email}
              label={t('email')}
              error={errors.email ? errors.email : null}
              autoFocus
            />
          </div>
          <Button
            type='primary'
            htmlType='submit'
            disabled={formState.isSubmitting}
          >
            {t('next')}
          </Button>
        </form>
      </div>
      <div className={dialogsStyle.right}>
        <h1>{t('forgotPasswordWhatNext')}</h1>
        <p>{t('forgotPasswordWhatNextInfo')}</p>
      </div>
    </div>
  );
};
