import Joi from '@hapi/joi';
import { passwordSchema } from '../../../../../utils/validation';

export interface ResetPasswordInputsSchema {
  password: string;
  repeatPassword: string;
}

export const resetPasswordInputSchema = Joi.object<ResetPasswordInputsSchema>({
  password: passwordSchema,
  repeatPassword: Joi.any().valid(Joi.ref('password')).required(),
});
