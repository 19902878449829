import Joi from '@hapi/joi';
import { emailSchema, passwordSchema } from '../../../../utils/validation';

export interface PasswordInputSchema {
  password: string;
}

export const passwordInputSchema = Joi.object<PasswordInputSchema>({
  password: passwordSchema,
});

export interface EmailInputSchema {
  email: string;
}

export const emailInputSchema = Joi.object<EmailInputSchema>({
  email: emailSchema,
});
