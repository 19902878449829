import React, { FC, useContext, useState } from 'react';
import { Dialog } from '../../../components/ui/Dialog/Dialog';
import { store } from '../../../store/store';
import { ConfirmationEmailSentContainer } from './DialogContent/ConfirmationEmailSent/ConfirmationEmailSentContainer';
import { ForgotPasswordContainer } from './DialogContent/ForgotPassword/ForgotPasswordContainer';
import { LoginEmailContainer } from './DialogContent/LoginEmail/LoginEmailContainer';
import { LoginPasswordContainer } from './DialogContent/LoginPassword/LoginPasswordContainer';
import { NewEmailConfirmationSuccess } from './DialogContent/NewEmailConfirmationSuccess/NewEmailConfirmationSuccess';
import { OtpConfirmationContainer } from './DialogContent/OtpConfirmation/OtpConfirmationContainer';
import { PasswordResetContainer } from './DialogContent/PasswordReset/PasswordResetContainer';
import { PhoneConfirmationContainer } from './DialogContent/PhoneConfirmation/PhoneConfirmationContainer';
import { RegisterPasswordContainer } from './DialogContent/RegisterPassword/RegisterPasswordContainer';
import { ResetPasswordEmailSentContainer } from './DialogContent/ResetPasswordEmailSent/ResetPasswordEmailSentContainer';
import { ResetPasswordSuccess } from './DialogContent/ResetPasswordSuccess/ResetPasswordSuccess';
import { Failure } from './DialogContent/Results/Failure';
import { Success } from './DialogContent/Results/Success';
import style from './HomePageDialog.module.scss';

interface HomePageDialogProps {
  handleDialogClose: () => void;
  showDialog: boolean;
}

export const HomePageDialog: FC<HomePageDialogProps> = ({ handleDialogClose, showDialog }) => {
  const { state } = useContext(store);
  const [loginEmail, setLoginEmail] = useState<string>(state.currentUser?.attributes.email ?? '');
  const [phoneNumber, setPhoneNumber] = useState<string>(state.currentUser?.attributes?.phone_number ?? '');
  const [isBookingFlow, setIsBookingFlow] = useState<boolean>(false);

  const DialogContent = () => {
    switch (state.homePageDialogState) {
      case 'loginEmail': {
        setIsBookingFlow(false);

        return <LoginEmailContainer setEmail={setLoginEmail} isBookingFlow={isBookingFlow} defaultEmail={loginEmail} />;
      }
      case 'loginEmailBookingFlow': {
        setIsBookingFlow(true);

        return <LoginEmailContainer setEmail={setLoginEmail} isBookingFlow={isBookingFlow} defaultEmail={loginEmail} />;
      }
      case 'loginPassword': {
        return <LoginPasswordContainer isBookingFlow={isBookingFlow} email={loginEmail} />;
      }
      case 'registerPassword': {
        return <RegisterPasswordContainer isBookingFlow={isBookingFlow} email={loginEmail} />;
      }
      case 'confirmationEmailSent': {
        return <ConfirmationEmailSentContainer email={loginEmail} />;
      }
      case 'phoneConfirmation': {
        return <PhoneConfirmationContainer defaultPhoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} />;
      }
      case 'otpConfirmation': {
        return <OtpConfirmationContainer phoneNumber={phoneNumber} />;
      }
      case 'forgotPassword': {
        return <ForgotPasswordContainer setEmail={setLoginEmail} email={loginEmail} />;
      }
      case 'resetPasswordEmailSent': {
        return <ResetPasswordEmailSentContainer email={loginEmail} />;
      }
      case 'resetPassword': {
        return <PasswordResetContainer />;
      }
      case 'resetPasswordSuccess': {
        return <ResetPasswordSuccess />;
      }
      case 'newEmailConfirmationSuccess': {
        return <NewEmailConfirmationSuccess />;
      }
      case 'endSuccess': {
        return <Success />;
      }
      case 'endFailure': {
        return <Failure />;
      }
    }

    return null;
  };

  return (
    <Dialog isOpen={showDialog} onRequestClose={handleDialogClose} className={style.modalContent}>
      <div className={style.header}>
        <img alt='Cerdio logo' src='/assets/logo.svg' />
        <span className={style.appName}>Cerdio</span>
      </div>
      <div className={style.content}>
        <DialogContent />
      </div>
    </Dialog>
  );
};
