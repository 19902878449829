export enum Path {
  BOOKING = '/booking',
  EMAIL_CONFIRMATION = '/auth/confirm-email',
  NEW_EMAIL_CONFIRMATION = '/auth/confirm-new-email',
  BOOKING_CALENDLY_REDIRECT = '/booking-redirect',
  HOME = '/',
  PRIVACY_POLICY = '/privacy-policy',
  PASSWORD_RESET = '/auth/reset-password',
  RESULTS = '/results',
  REQUEST_FORM_DETAILS = '/request-form-details',
  SETTINGS = '/settings',
  TERMS = '/terms',
  DONE = '/done',
}
