import { joiResolver } from '@hookform/resolvers';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../../../components/ui/Button/Button';
import { Checkbox } from '../../../../../components/ui/Checkbox/Checkbox';
import { Icon } from '../../../../../components/ui/Icon/Icon';
import { Input } from '../../../../../components/ui/Input/Input';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { LayoutLocales, MainLocales } from '../../../../../locales/locales.interfaces';
import { Path } from '../../../../../types/enums/path.enum';
import dialogsStyle from '../Dialogs.module.scss';
import style from './RegisterPassword.module.scss';
import { registerPasswordInputsSchema, RegisterPasswordInputsSchemaInterface } from './validation';

interface Props {
  email: string;
  handleBackIconClick: () => void;
  onDataSubmit: (data: { password: string }) => Promise<void>;
}

export const RegisterPassword: FC<Props> = ({ email, onDataSubmit, handleBackIconClick }) => {
  const { t } = useTranslation<MainLocales>('mainLocales');
  const { t: layoutT } = useTranslation<LayoutLocales>('layoutLocales');
  const { control, handleSubmit, errors, formState, setValue } = useForm<RegisterPasswordInputsSchemaInterface>({
    resolver: joiResolver(registerPasswordInputsSchema),
  });

  return (
    <div className={dialogsStyle.content}>
      <div className={dialogsStyle.left}>
        <Icon alt='<-' type='ic-back' onClick={handleBackIconClick} marginBottom/>
        <form onSubmit={handleSubmit(onDataSubmit)}>
          <h1>{t('registerPasswordHeader')}</h1>
          <p>{email}</p>
          <div className={style.passwordContainer}>
            <Input
              name='password'
              control={control}
              defaultValue=''
              label={t('registerSetupPassword')}
              error={errors.password ? errors.password : null}
              type='password'
              autoFocus
            />
            <p className={style.passwordRestrictions}>{t('registerPasswordRestrictions')}</p>
          </div>
          <Checkbox
            defaultValue={false}
            name='agreement'
            control={control}
            error={errors.agreement ? { type: 'required' } : null}
            className={style.agreeTerms}
            onChange={({ target }) => setValue('agreement',target.checked, { shouldValidate: true })}
            required
          >
            {t('registerPasswordAgreeTerms1')}
            <a target='_blank' rel='noopener noreferrer' href={Path.TERMS}>{layoutT('terms')}</a>
            {t('registerPasswordAgreeTerms2')}
            <a target='_blank' rel='noopener noreferrer' href={Path.PRIVACY_POLICY}>{layoutT('privacyPolicy')}</a>
            {t('registerPasswordAgreeTerms3')}
          </Checkbox>
          <Button
            type='primary'
            htmlType='submit'
            disabled={!formState.isDirty || formState.isSubmitting}
            className={style.registerButton}
          >
            {t('registerPasswordRegisterButton')}
          </Button>
        </form>
      </div>
      {/*<div className={dialogsStyle.right}></div>*/}
    </div>
  );
};
