import { Radio as AntRadio } from 'antd';
import { RadioChangeEvent, RadioProps as AntdRadioProps } from 'antd/lib/radio';
import cx from 'classnames';
import React, { CSSProperties, FC } from 'react';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';
import { WithLabelAndError, WithLabelAndErrorProps } from '../hoc/WithLabelAndError';
import style from './RadioGroup.module.scss';

export interface RadioGroupProps extends WithLabelAndErrorProps, Pick<AntdRadioProps, 'disabled'> {
  control: Control;
  defaultValue?: string;
  name: string;
  onChange: (e: RadioChangeEvent) => void;
  radioStyle?: CSSProperties;
  rules?: any;
}

/**
 * @param onChange - IMPORTANT has to be passed
 */
export const RadioGroup: FC<RadioGroupProps> = ({ required, error, onChange, label, ...props }) => {
  return (
    <WithLabelAndError error={error} required={required} label={label}>
      <Controller
        rules={{ required }}
        render={data => <AntRadio.Group {...props} {...data} onChange={onChange}/>}
        {...props}
      />
    </WithLabelAndError>
  );
};

export interface RadioProps extends AntdRadioProps {
  layout?: 'horizontal' | 'vertical';
}

export const Radio: FC<RadioProps> = ({ children, layout = 'vertical', ...props }) =>
  <AntRadio
    className={cx(style.radio, { [style.vertical]: layout === 'vertical' } )}
    {...props}
  >
    {children}
  </AntRadio>;
