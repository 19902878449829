import cx from 'classnames';
import React, { FC } from 'react';
import ReactModal, { Props } from 'react-modal';
import { CSSTransition } from 'react-transition-group';
import { TRANSITION_TIMEOUT } from '../../../styles/styles';
import { Icon } from '../Icon/Icon';
import style from './Dialog.module.scss';
import './ModalPortal.scss';

export const Dialog: FC<Props> = ({ children, isOpen, className, onRequestClose, ...props }) => {
  return (
    <CSSTransition
      in={isOpen}
      timeout={TRANSITION_TIMEOUT}
    >
      <ReactModal
        closeTimeoutMS={TRANSITION_TIMEOUT}
        ariaHideApp={false}
        className={cx(style.modalContent, className)}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        overlayClassName={style.modalOverlay}
        parentSelector={() => document.getElementById('dialog-root')!}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        {...props}
      >
        <Icon type='ic-close' alt='x' onClick={onRequestClose} className={style.closeButton}/>
        {children}
      </ReactModal>
    </CSSTransition>
  );
};
