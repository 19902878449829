import { joiResolver } from '@hookform/resolvers';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../components/ui/Button/Button';
import { Input } from '../../../components/ui/Input/Input';
import { useTranslation } from '../../../hooks/useTranslation';
import { MainLocales } from '../../../locales/locales.interfaces';
import style from '../ChangeForms.module.scss';
import { changeEmailInputSchema, ChangeEmailInputsSchema } from './validation';

interface Props {
  email: string;
  onDataSubmit: (data: ChangeEmailInputsSchema) => Promise<void>;
}

export const ChangeEmailForm: FC<Props> = ({ email, onDataSubmit }) => {
  const { t } = useTranslation<MainLocales>('mainLocales');
  const { control, handleSubmit, errors, formState } = useForm<ChangeEmailInputsSchema>({
    resolver: joiResolver(changeEmailInputSchema(email)),
  });

  return (
    <form className={style.form} onSubmit={handleSubmit(onDataSubmit)}>
      <div className={style.inputsContainer}>
        <Input
          name='newEmail'
          control={control}
          defaultValue=''
          label={t('newEmailAddress')}
          error={errors.newEmail!}
          className={style.column}
        />
        <Input
          name='currentPassword'
          control={control}
          defaultValue=''
          label={t('currentPasswordConfirm')}
          error={errors.currentPassword!}
          className={style.column}
          iconRender={() => null}
          type='password'
        />
      </div>
      <Button
        type='primary'
        htmlType='submit'
        disabled={!formState.isDirty || formState.isSubmitting}
        className={style.button}
      >
        {t('update')}
      </Button>
    </form>
  );
};
