import { NotificationsLocales } from '../locales/locales.interfaces';
import { AuthCodeErrorCode } from '../types/enums/auth-code-error-code.enum';
import { ErrorResponse } from '../types/interfaces/error-response.interface';

export const getAuthCodeNotificationKey =  (error: ErrorResponse): keyof NotificationsLocales => {
  switch (error.details as AuthCodeErrorCode) {
    case AuthCodeErrorCode.EXPIRED: return 'linkExpired';
    case AuthCodeErrorCode.USED: return 'linkUsed';
    case AuthCodeErrorCode.REVOKED: return 'linkExpired';
    case AuthCodeErrorCode.NOT_FOUND: return 'linkInvalid';
  }
};
