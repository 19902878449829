import React, { useContext } from 'react';
import { showErrorNotification } from '../../../../../components/ui/Notifications/Notifications';
import { store } from '../../../../../store/store';
import { StoreActionType } from '../../../../../store/store-types';
import { ApiPath } from '../../../../../types/enums/api-path.enum';
import { BusinessErrorCode } from '../../../../../types/enums/business-error-code.enum';
import { HomePageDialogState } from '../../../../../types/enums/home-page-dialog-state.enum';
import { getAuthCodeNotificationKey } from '../../../../../utils/error-handlers';
import { Fetch } from '../../../../../utils/fetch';
import { hideHomePageDialog, showHomePageDialog } from '../../../../../utils/home-page-dialog-state-handlers';
import { logger } from '../../../../../utils/logger';
import { PasswordReset } from './PasswordReset';

export const PasswordResetContainer = () => {
  const { state, dispatch } = useContext(store);

  const handleResetPasswordButton = async ({ password }: { password: string }) => {
    try {
      await Fetch.post(ApiPath.RESET_PASSWORD, { code: state.resetPasswordData!.code, password });
      showHomePageDialog(dispatch, HomePageDialogState.resetPasswordSuccess);
      dispatch({ type: StoreActionType.SET_RESET_PASSWORD_DATA, payload: null });
    } catch (error) {
      logger.error(error);
      hideHomePageDialog(dispatch);
      dispatch({ type: StoreActionType.SET_RESET_PASSWORD_DATA, payload: null });
      showErrorNotification(error.response!.data, {
        config: {
          [BusinessErrorCode.VALIDATION_ERROR]: getAuthCodeNotificationKey(error.response!.data),
        }
      });
    }
  };

  return <PasswordReset email={state.resetPasswordData!.email} onDataSubmit={handleResetPasswordButton}/>;
};
