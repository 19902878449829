import React, { useCallback, useContext } from 'react';
import { store } from '../../../store/store';
import { changePassword } from '../../../utils/auth';
import { ChangePasswordForm } from './ChangePasswordForm';
import { ChangePasswordInputsSchema } from './validation';

export const ChangePasswordFormContainer = () => {
  const { state } = useContext(store);
  const onDataSubmit = useCallback(async ({ currentPassword, newPassword }: ChangePasswordInputsSchema) => {
    await changePassword(state.currentUser!, currentPassword, newPassword);
  }, [state.currentUser]);

  return <ChangePasswordForm onDataSubmit={onDataSubmit}/>;
};
