import cx from 'classnames';
import React, { CSSProperties, FC, RefObject, useRef } from 'react';
import { FieldError } from 'react-hook-form/dist/types/form';
import { useTranslation } from '../../../hooks/useTranslation';
import { ValidationLocales } from '../../../locales/locales.interfaces';
import style from './WithLabelAndError.module.scss';

export interface WithLabelAndErrorProps {
  className?: string;
  error: FieldError | null;
  label?: string;
  required?: boolean;
  style?: CSSProperties;
}

const scrollToRef = (ref: RefObject<any>) => window.scrollTo(0, ref.current.offsetTop);

export const WithLabelAndError: FC<WithLabelAndErrorProps> = ({ required, label, error, children, className }) => {
  const { t } = useTranslation<ValidationLocales>('validation');
  const ref = useRef(null);
  if (ref && error) {
    scrollToRef(ref);
  }

  return (
    <div className={cx(style.container, className)} ref={ref}>
      {label && <p className={style.label}>{label}{required && ' *'}</p>}
      {children}
      {error && <p className={style.error} role='alert'>{error && t(error.type as keyof ValidationLocales)}</p>}
    </div>
  );
};
