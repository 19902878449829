import { useContext, useEffect } from 'react';
import { store } from '../../../store/store';
import { HomePageDialogState } from '../../../types/enums/home-page-dialog-state.enum';
import { UserVerificationState } from '../../../types/enums/user-verification-state.enum';
import { getUserVerificationState } from '../../../utils/auth';
import { showHomePageDialog } from '../../../utils/home-page-dialog-state-handlers';

export const useShouldVerifyAccount = () => {
  const { state, dispatch } = useContext(store);

  useEffect(() => {
    switch (getUserVerificationState(state.currentUser!)) {
      case UserVerificationState.EMAIL_NOT_VERIFIED: {
        return showHomePageDialog(dispatch, HomePageDialogState.confirmationEmailSent);
      }
      case UserVerificationState.PHONE_NUMBER_NOT_VERIFIED: {
        if (state.currentUser?.attributes.phone_number) {
          return showHomePageDialog(dispatch, HomePageDialogState.otpConfirmation);
        }

        return showHomePageDialog(dispatch, HomePageDialogState.phoneConfirmation);
      }
    }
  }, [dispatch, state.currentUser]);
};
