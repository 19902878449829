import Joi, { ErrorReport } from '@hapi/joi';
import { PhoneNumberUtil } from 'google-libphonenumber';

export const setCustomValidationError = (defaultErrorCode: string, customErrorCode: string): any => (errors: ErrorReport[]) =>
  errors.map(err => {
    if (err.code === defaultErrorCode) {
      err.code = customErrorCode;
    }

    return err;
  });

// tslint:disable-next-line:no-var-requires
export const phoneNumberSchema = Joi.string().required().custom((value, helpers) => {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const phoneNumber = phoneUtil.parseAndKeepRawInput(value);
    const region = phoneUtil.getRegionCodeForCountryCode(
      phoneNumber.getCountryCode()!
    );
    const isValid = phoneUtil.isValidNumberForRegion(phoneNumber, region);
    if (isValid) {
      return value;
    }
    // tslint:disable-next-line:no-empty
  } catch {}

  return helpers.error('phoneNumber.invalid');
});

export const emailSchema = Joi.string().email({ tlds: false }).required();

const passwordRegex = new RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[A-Za-z\\d !"#$%&()*+,-./:;<=>?@[\\]^_`{|}~]{12,99}$'
);

export const passwordSchema = Joi.string().pattern(passwordRegex).required();

export interface EmailRedirectParamsSchema {
  code: string;
  email: string;
}

export const emailRedirectParamsSchema = Joi.object<EmailRedirectParamsSchema>({
  code: Joi.string().uuid({ version: 'uuidv4' }).required(),
  email: emailSchema.optional(),
});
