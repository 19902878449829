import Joi from '@hapi/joi';
import { PatientConfirmBookingDto } from '../../types/api-types';
import { DocumentType } from '../../types/enums/document-type.enum';
import { Gender } from '../../types/enums/gender.enum';
import { SendResultsTo } from '../../types/enums/send-results-to.enum';
import { emailSchema, phoneNumberSchema } from '../../utils/validation';
import { CalendlyRedirectParams } from './interfaces/calendly-redirect-params.interface';

export const bookingCalendlyRedirectParamsSchema = Joi.object<CalendlyRedirectParams>({
  answer_1: Joi.string().optional(), // TODO may be required
  assigned_to: Joi.string().required(),
  event_end_time: Joi.string().required().isoDate(),
  event_start_time: Joi.string().required().isoDate(),
  event_type_name: Joi.string().required(),
  event_type_uuid: Joi.string().required(),
  invitee_email: emailSchema,
  invitee_full_name: Joi.string().required(),
  invitee_uuid: Joi.string().required(),
});

export interface BookingCalendlyRedirectInputsSchema extends PatientConfirmBookingDto {
  email: string;
  phoneNumber: string;
}

const optionalString = Joi.string().allow('').optional();
const requiredString = Joi.string().required();

export const bookingCalendlyRedirectInputsSchema = Joi.object<BookingCalendlyRedirectInputsSchema>({
  birthDate: Joi.required(),
  documentId: requiredString,
  email: requiredString.email({ tlds: false }),
  phoneNumber: requiredString,
  name: requiredString,
  gender: Joi.allow(Gender).required(),
  nationality: requiredString,
});
