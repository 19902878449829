import React, { CSSProperties, FC, useEffect, useState } from 'react';
import colors from '../../styles/colors.scss';
import font from '../../styles/font.scss';
import { Loader } from '../ui/Loader/Loader';

const { REACT_APP_CALENDLY_API_URL } = process.env;

interface Props {
  email?: string;
  name?: string;
  style: CSSProperties;
}

export const CalendlyWidget: FC<Props> = ({ style, email, name }) => {
  const primaryColor = colors.primaryColor.replace('#', '');
  const textColor = font.textColorDark.replace('#', '');
  const [isLoading, setLoading] = useState(!window.Calendly);
  const dataUrl = REACT_APP_CALENDLY_API_URL + `?text_color=${textColor}&primary_color=${primaryColor}`;

  useEffect(() => {
    if (window.Calendly) {
      setLoading(false);
      window.Calendly.initInlineWidget({
        url: dataUrl,
        parentElement: document.querySelector('.calendly-inline-widget')!,
        prefill: { email, name },
      });
    } else {
      const head: HTMLHeadElement = document.querySelector('head')!;
      const script = document.createElement('script');
      const calendlyScriptId = 'calendly_external_widget_script';

      script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
      script.setAttribute('id', calendlyScriptId);
      script.onload = () => setLoading(false);
      head.appendChild(script);

      return () => {
        head.removeChild(document.getElementById(calendlyScriptId)!);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUrl, email, window.Calendly]);

  if (isLoading) {
    return <Loader fullscreen size='large'/>;
  }

  return (
    <div
      className='calendly-inline-widget'
      data-auto-load={false}
      style={style}
    />
  );
};
