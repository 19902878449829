import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Loader } from '../../components/ui/Loader/Loader';
import { showErrorNotification } from '../../components/ui/Notifications/Notifications';
import { store } from '../../store/store';
import { StoreActionType } from '../../store/store-types';
import { ApiPath } from '../../types/enums/api-path.enum';
import { BusinessErrorCode } from '../../types/enums/business-error-code.enum';
import { HomePageDialogState } from '../../types/enums/home-page-dialog-state.enum';
import { Path } from '../../types/enums/path.enum';
import { refreshCurrentUser } from '../../utils/auth';
import { getAuthCodeNotificationKey } from '../../utils/error-handlers';
import { Fetch, FetchErrorResponse } from '../../utils/fetch';
import { showHomePageDialog } from '../../utils/home-page-dialog-state-handlers';
import { logger } from '../../utils/logger';
import { parseQueryParams } from '../../utils/parsers';
import { emailRedirectParamsSchema } from '../../utils/validation';

export const EmailConfirmationPage = () => {
  const location = useLocation();
  const { error: queryParamsValidationError, value: queryParams } = parseQueryParams(location, emailRedirectParamsSchema);
  const { push } = useHistory();
  const { dispatch, state } = useContext(store);

  useEffect(() => {
    if (queryParamsValidationError) {
      return push(Path.HOME);
    }

    Fetch.post(ApiPath.CONFIRM_EMAIL, queryParams)
      .then(async () => {
        if (!state.currentUser) {
          dispatch({ type: StoreActionType.SET_CONFIRMED_EMAIL, payload: queryParams.email });
          showHomePageDialog(dispatch, HomePageDialogState.loginEmail);
        } else {
          await refreshCurrentUser(dispatch);
        }

        push(Path.HOME);
      })
      .catch((error: FetchErrorResponse) => {
        logger.error(error);
        showErrorNotification(error.response!.data, {
          config: {
            [BusinessErrorCode.VALIDATION_ERROR]: getAuthCodeNotificationKey(error.response!.data),
          },
        });
        push(Path.HOME);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loader fullscreen size='large' />;
};
