import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Path } from '../types/enums/path.enum';
import { BookingCalendlyRedirectPageContainer } from './BookingCalendlyRedirectPage/BookingCalendlyRedirectPageContainer';
import { BookingPageContainer } from './BookingPage/BookingPageContainer';
import { EmailConfirmationPage } from './EmailConfirmationPage/EmailConfirmationPage';
import { FlightChoicePageContainer } from './FlightChoicePage/FlightChoicePageContainer';
import { PrivateRoute } from './hoc/PrivateRoute';
import {HomePageContainer} from './HomePage/HomePageContainer';
import { NewEmailConfirmationPage } from './NewEmailConfirmationPage/NewEmailConfirmationPage';
import { PasswordResetPage } from './PasswordResetPage/PasswordResetPage';
import { RequestFormDetailsPageContainer } from './RequestFormDetailsPage/RequestFormDetailsPageContainer';
import { ResultsPageContainer } from './ResultsPage/ResultsPageContainer';
import { SettingsPageContainer } from './SettingsPage/SettingsPageContainer';

// TODO use API configuration instead
const { REACT_APP_RESULTS_PAGE } = process.env;
const showResultsPage = REACT_APP_RESULTS_PAGE?.toString() === 'true';

export const Routes = () => {
  return (
    <Switch>
      <Route exact path={Path.HOME} key={Path.HOME} component={FlightChoicePageContainer}/>
      <Route exact path={Path.DONE} key={Path.DONE} component={HomePageContainer}/>
      <Route exact path={Path.PRIVACY_POLICY} key={Path.PRIVACY_POLICY} component={() => <div>TODO PRIVACY POLICY</div>}/>
      <Route exact path={Path.TERMS} key={Path.TERMS} component={() => <div>TODO TERMS</div>}/>
      <Route exact path={Path.EMAIL_CONFIRMATION} key={Path.EMAIL_CONFIRMATION} component={EmailConfirmationPage}/>
      <Route exact path={Path.NEW_EMAIL_CONFIRMATION} key={Path.NEW_EMAIL_CONFIRMATION} component={NewEmailConfirmationPage}/>
      <Route exact path={Path.PASSWORD_RESET} key={Path.PASSWORD_RESET} component={PasswordResetPage}/>
      <Route exact path={Path.BOOKING_CALENDLY_REDIRECT} key={Path.BOOKING_CALENDLY_REDIRECT}
                    component={BookingCalendlyRedirectPageContainer}/>
      <PrivateRoute exact path={Path.BOOKING} key={Path.BOOKING} component={BookingPageContainer}/>
      <Route exact path={Path.REQUEST_FORM_DETAILS} key={Path.REQUEST_FORM_DETAILS} component={RequestFormDetailsPageContainer}/>
      <PrivateRoute exact path={Path.SETTINGS} key={Path.SETTINGS} component={SettingsPageContainer}/>
      {
        showResultsPage && <PrivateRoute exact path={Path.RESULTS} key={Path.RESULTS} component={ResultsPageContainer}/>
      }
      <Route exact path='*' key='*' component={() => <Redirect to={Path.HOME}/>}/>
    </Switch>
  );
};
