import { DatePicker as AntDatePicker } from 'antd';
import { DatePickerProps as AntDatePickerProps } from 'antd/lib/date-picker';
import cx from 'classnames';
import moment, { Moment } from 'moment';
import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';
import { WithLabelAndError, WithLabelAndErrorProps } from '../hoc/WithLabelAndError';
import style from './DatePicker.module.scss';

export interface DatePickerProps extends WithLabelAndErrorProps, Pick<AntDatePickerProps, 'disabled' | 'placeholder'> {
  control: Control;
  defaultValue?: Moment;
  format?: string;
  inputClassName?: string;
  name: string;
  rules?: any;
}

export const handleDatePickerRange = (current: Moment) => {
  const minDate = moment('01-01-1900');
  const maxDate = moment();

  return current && (current.isSameOrBefore(minDate) || current.isSameOrAfter(maxDate));
};

export const DatePicker: FC<DatePickerProps> = ({ className, required, error, label, inputClassName, ...props}) => {
  return (
    <WithLabelAndError className={cx(style.datePickerContainer, className )} error={error} required={required} label={label}>
      <Controller
        as={AntDatePicker}
        size='large'
        format='DD.MM.YYYY'
        rules={{ required }}
        disabledDate={handleDatePickerRange}
        className={cx(style.datePicker, inputClassName)}
        // @ts-ignore
        showToday={false}
        {...props}
      />
    </WithLabelAndError>
  );
};

export const handleDatePickerRange2 = (current: Moment) => {
  return current && (current.isSameOrBefore(moment().subtract(1, "days")));
};

export const DatePicker2: FC<DatePickerProps> = ({ className, required, error, label, inputClassName, ...props}) => {
  return (
    <WithLabelAndError className={cx(style.datePickerContainer, className )} error={error} required={required} label={label}>
      <Controller
        as={AntDatePicker}
        size='large'
        format='DD.MM.YYYY'
        rules={{ required }}
        disabledDate={handleDatePickerRange2}
        className={cx(style.datePicker, inputClassName)}
        // @ts-ignore
        showToday={false}
        {...props}
      />
    </WithLabelAndError>
  );
};
