import { Checkbox as AntCheckbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CheckboxProps } from 'antd/lib/checkbox';
import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { Control, FieldError } from 'react-hook-form/dist/types/form';
import { WithLabelAndError, WithLabelAndErrorProps } from '../hoc/WithLabelAndError';

export interface Props extends WithLabelAndErrorProps, CheckboxProps {
  control: Control;
  defaultValue?: boolean;
  error: FieldError | null;
  label?: string;
  name: string;
  onChange: (e: CheckboxChangeEvent) => void;
  required?: boolean;
  rules?: any;
}

/**
 * @param onChange - IMPORTANT has to be passed
 */
export const Checkbox: FC<Props> = ({ required, label, error, onChange, ...props }) => {
  return (
    <WithLabelAndError error={error} required={required} label={label}>
      <Controller
        rules={{ required }}
        render={({ value }) => <AntCheckbox defaultChecked={value} checked={value} onChange={onChange} {...props}/>}
        {...props}
      />
    </WithLabelAndError>
  );
};
