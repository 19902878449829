import React from 'react';
import { Routes } from '../../routes/Routes';
import { ErrorBoundary } from '../Errors/ErrorBoundary';
import { Layout } from '../Layout/Layout';

export const App = () => {
  return (
    <Layout>
      <ErrorBoundary>
        <Routes/>
      </ErrorBoundary>
    </Layout>
  );
};
