import React, { FC, useContext } from 'react';
import { showErrorNotification } from '../../../../../components/ui/Notifications/Notifications';
import { store } from '../../../../../store/store';
import { ApiPath } from '../../../../../types/enums/api-path.enum';
import { HomePageDialogState } from '../../../../../types/enums/home-page-dialog-state.enum';
import { HttpStatus } from '../../../../../types/enums/http-status.enum';
import { Fetch } from '../../../../../utils/fetch';
import { showHomePageDialog } from '../../../../../utils/home-page-dialog-state-handlers';
import { LoginEmail } from './LoginEmail';

export interface LoginEmailContainerProps {
  defaultEmail?: string;
  isBookingFlow: boolean;
  setEmail: (email: string) => void;
}

export const LoginEmailContainer: FC<LoginEmailContainerProps> = ({ defaultEmail, isBookingFlow, setEmail }) => {
  const { dispatch, state } = useContext(store);

  const checkEmail = async ({ email }: { email: string }) => {
    try {
      await Fetch.head(`${ApiPath.CHECK_EMAIL}/${email}`);
      showHomePageDialog(dispatch, HomePageDialogState.loginPassword);
    } catch (error) {
      if (error.response.status === HttpStatus.NOT_FOUND) {
        showHomePageDialog(dispatch, HomePageDialogState.registerPassword);
      } else {
        showErrorNotification(error);
      }
    } finally {
      setEmail(email.toLowerCase());
    }
  };

  return <LoginEmail isBookingFlow={isBookingFlow} onDataSubmit={checkEmail} defaultEmail={defaultEmail || state.confirmedEmail} />;
};
