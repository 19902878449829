import Joi from '@hapi/joi';
import { phoneNumberSchema } from '../../../../../utils/validation';

export interface PhoneConfirmationInputsSchemaInterface {
  phoneNumber: string;
}

export const phoneConfirmationInputsSchema = Joi.object<
  PhoneConfirmationInputsSchemaInterface
>({
  phoneNumber: phoneNumberSchema,
});
