import { joiResolver } from '@hookform/resolvers';
import cx from 'classnames';
import moment from 'moment';
import React, {FC, useContext, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { MAX_DOCUMENT_LENGTH, MAX_POSTAL_CODE_LENGTH, MAX_VEHICLE_REGISTRATION_PLATE_LENGTH, SUPPORT_EMAIL } from '../../constants/common';
import { useTranslation } from '../../hooks/useTranslation';
import { getCountryNames } from '../../locales/countries';
import { MainLocales } from '../../locales/locales.interfaces';
import { CalendlyRedirectParams } from '../../routes/BookingCalendlyRedirectPage/interfaces/calendly-redirect-params.interface';
import { bookingCalendlyRedirectInputsSchema, BookingCalendlyRedirectInputsSchema } from '../../routes/BookingCalendlyRedirectPage/validation';
import { store } from '../../store/store';
import { Doctor, Patient } from '../../types/api-types';
import { DocumentType } from '../../types/enums/document-type.enum';
import { Gender } from '../../types/enums/gender.enum';
import {HomePageDialogState} from '../../types/enums/home-page-dialog-state.enum';
import {Path} from '../../types/enums/path.enum';
import { SendResultsTo } from '../../types/enums/send-results-to.enum';
import {showHomePageDialog} from '../../utils/home-page-dialog-state-handlers';
import { AutoComplete } from '../ui/AutoComplete/AutoComplete';
import { Button } from '../ui/Button/Button';
import { Checkbox } from '../ui/Checkbox/Checkbox';
import { DatePicker } from '../ui/DatePicker/DatePicker';
import { Input } from '../ui/Input/Input';
import { PhoneNumberInput } from '../ui/PhoneNumberInput/PhoneNumberInput';
import { Radio, RadioGroup } from '../ui/RadioGroup/RadioGroup';
import { Option, Select } from '../ui/Select/Select';
import style from './BookingForm.module.scss';

// TODO use API configuration instead
const { REACT_APP_RESULTS_PAGE } = process.env;
const showResultsPage = REACT_APP_RESULTS_PAGE?.toString() === 'true';

interface Props {
  // calendlyRedirectParams?: CalendlyRedirectParams;
  // defaultDoctorId?: string;
  // doctorList: Doctor[];
  // email: string;
  onDataSubmit?: (data: any) => Promise<void>;
  // patient?: Patient;
  // phoneNumber: string;
  readonly?: boolean;
}

export const BookingForm: FC<Props> = props => {
  const { push } = useHistory();
  const { state, dispatch } = useContext(store);
  const { flight } = state;
  const [ submitting, setSubmitting ] = useState(false);
  const { t } = useTranslation<MainLocales>('mainLocales');
  const { control, handleSubmit, errors, formState, getValues, setValue, clearErrors } = useForm<BookingCalendlyRedirectInputsSchema>({
    resolver: joiResolver(bookingCalendlyRedirectInputsSchema),
  });
  const readonly = false;

  const onSubmit = values => {
    setSubmitting(true);
    console.log(values);
    const { REACT_APP_API_URL } = process.env;
    fetch(`${REACT_APP_API_URL}/bookings/`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      // mode: 'cors', // no-cors, *cors, same-origin
      headers: {
        'Content-Type': 'application/vnd.api+json'
      },
      body: JSON.stringify({
        "data": {
          "type": "bookings",
          "id": null,
          "attributes": {
            "patientName": values.name,
            "patientEmail": values.email,
            "patientPhone": values.phoneNumber,
            "patientNationality": values.nationality,
            "patientDob": values.birthDate.format('YYYY-MM-DD'),
            "patientGender": values.gender === 'MALE' ? 'M' : 'F',
            "passportNumber": values.documentId,
            "appointmentAt": moment(flight.arrival.scheduledTimeLocal).toISOString(),
            "flightNumber": flight.number.replace(' ', ''),
            "flightAirline": flight.airline.name,
            "flightOrigin": flight.departure.airport.name
          }
        }
      }) // body data type must match "Content-Type" header
    }).then((response) => {
      console.log(response);
      showHomePageDialog(dispatch, HomePageDialogState.endSuccess);
      push(Path.DONE);
    }).catch((error) => {
      console.log(error);
    });
    // push(Path.REQUEST_FORM_DETAILS);
  };

  const PageSubheader = () => {
    return readonly
      ? (
        <p className={cx(style.singleColumn, style.pageSubheader)}>
          <span style={{ display: 'block' }}>{t('bookingFormSubheaderReadonly1')}</span>
          <span>{t('bookingFormSubheaderReadonly2')}</span>
          <span style={{ fontWeight: 600 }}><a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a></span>
          <span>.</span>
        </p>
      )
      : (
        <>
          <p className={cx(style.column, style.pageSubheader)}>
            {t('bookingFormSubheader')}
          </p>
          <div className={cx(style.column, style.mobileOnly)} />
        </>
      );
  };

  return (
    <div className={style.content}>
      <h1 className={style.pageHeader}>{t('bookingFormHeader')}</h1>
      <div className={style.row}>
        <PageSubheader />
      </div>

      <form onSubmit={onSubmit && handleSubmit(onSubmit)}>
        <p className={style.header}>
          {t('bookingFormPersonalHeader').split('\n').map((item, key) => <span key={key}>{item}<br /></span>)}
        </p>
        <div className={style.row}>
          <div className={style.column}>
            <Input
              control={control}
              name='name'
              defaultValue={''}
              label={t('name')}
              className={style.field}
              error={errors.name!}
              disabled={readonly}
              required
            />
            <Input
              control={control}
              name='nationality'
              label={t('nationality')}
              className={style.field}
              error={errors.nationality!}
              defaultValue={''}
              disabled={readonly}
              required
            />
            <Input
              control={control}
              name={'documentId'}
              label={t(DocumentType.PASSPORT)}
              className={style.field}
              error={errors.documentId}
              defaultValue={''}
              maxLength={MAX_DOCUMENT_LENGTH}
              required={true}
              disabled={readonly}
            />
          </div>
          <div className={style.column}>
            <Input
              control={control}
              name='email'
              label={t('email')}
              defaultValue={''}
              className={style.field}
              error={errors.email!}
              required
            />
            <PhoneNumberInput
              name='phoneNumber'
              error={null}
              control={control}
              className={style.field}
              label={t('phoneNumber')}
              required
            />
            <DatePicker
              control={control}
              name='birthDate'
              format={'DD.MM.YYYY'}
              label={t('birthDate')}
              className={style.field}
              error={errors.birthDate!}
              disabled={readonly}
              placeholder={readonly ? '' : t('selectDate')}
              required
            />
            <RadioGroup
              name='gender'
              control={control}
              label={t('gender')}
              className={style.field}
              error={errors.gender!}
              onChange={({ target }) => {
                setValue('gender', target.value);
                clearErrors('gender');
              }}
              defaultValue={''}
              disabled={readonly}
              required
            >
              <Radio value={Gender.MALE} layout='horizontal'>{t(Gender.MALE)}</Radio>
              <Radio value={Gender.FEMALE} layout='horizontal'>{t(Gender.FEMALE)}</Radio>
            </RadioGroup>
          </div>
        </div>
        {
          !readonly && (
            <Button
              type='primary'
              htmlType='submit'
              className={style.submitButton}
              disabled={submitting}
            >
              {t('savePay')}
            </Button>
          )
        }
      </form>
    </div>
  );
};
