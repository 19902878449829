import React from 'react';
import { Loader } from '../../components/ui/Loader/Loader';
import { usePatient } from '../../hooks/usePatient';
import { usePatientResults } from './hooks/usePatientResults';
import { ResultsPage } from './ResultsPage';

export const ResultsPageContainer = () => {
  const { isLoadingPatient, patient } = usePatient();
  const { isLoadingResults, results } = usePatientResults();

  if (isLoadingPatient || isLoadingResults) {
    return <Loader fullscreen size='large' />;
  }

  return <ResultsPage patient={patient?.patient} results={results}/>;
};
