import React, { FC, useCallback, useContext } from 'react';
import { useSendPhoneConfirmationCode } from '../../../../../hooks/useSendPhoneConfirmationCode';
import { store } from '../../../../../store/store';
import { HomePageDialogState } from '../../../../../types/enums/home-page-dialog-state.enum';
import { RecaptchaActionType } from '../../../../../types/enums/recaptcha-action-type.enum';
import { verifyRecaptcha } from '../../../../../utils/auth';
import { showHomePageDialog } from '../../../../../utils/home-page-dialog-state-handlers';
import { PhoneConfirmation } from './PhoneConfirmation';

interface Props {
  defaultPhoneNumber: string;
  setPhoneNumber: (phoneNumber: string) => void;
}

export const PhoneConfirmationContainer: FC<Props> = ({ setPhoneNumber, defaultPhoneNumber }) => {
  const { dispatch } = useContext(store);
  const { mutation: sendConfirmationCode } = useSendPhoneConfirmationCode();

  const onPhoneNumberSubmit: (data: { phoneNumber: string }) => Promise<void> = useCallback(async ({ phoneNumber }) => {
    await verifyRecaptcha(RecaptchaActionType.VERIFY_PHONE, { phoneNumber });
    const result = await sendConfirmationCode({ phoneNumber });
    if (result?.success) {
      setPhoneNumber(phoneNumber);
      showHomePageDialog(dispatch, HomePageDialogState.otpConfirmation);
    }
  }, [sendConfirmationCode, setPhoneNumber, dispatch]);

  return <PhoneConfirmation onPhoneNumberSubmit={onPhoneNumberSubmit} defaultPhoneNumber={defaultPhoneNumber} />;
};
