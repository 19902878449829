import React, { FC } from 'react';
import { BookingForm } from '../../components/BookingForm/BookingForm';
import { BookingLeavingGuard } from '../../components/BookingLeavingGuard/BookingLeavingGuard';
import { Doctor, Patient } from '../../types/api-types';
import { Path } from '../../types/enums/path.enum';
import { CalendlyRedirectParams } from './interfaces/calendly-redirect-params.interface';

export interface Props {
  // calendlyRedirectParams: CalendlyRedirectParams;
  // doctorList: Doctor[];
  // email: string;
  onDataSubmit: (data: any) => Promise<void>;
  // patient?: Patient;
  // phoneNumber: string;
}

export const BookingCalendlyRedirectPage: FC<Props> = props => {
  return (
    <>
      <BookingForm {...props} />
      <BookingLeavingGuard
        when={true}
        // This case it blocks the navigation when user is going to different page that calendly redirect page or /payments (temporary /home)
        shouldBlockNavigation={newLocation => ![Path.HOME, Path.BOOKING_CALENDLY_REDIRECT].includes(newLocation.pathname as Path)}
      />
    </>
  );
};
