import React, { FC, useCallback, useContext } from 'react';
import { useSendPhoneConfirmationCode } from '../../../../../hooks/useSendPhoneConfirmationCode';
import { store } from '../../../../../store/store';
import { HomePageDialogState } from '../../../../../types/enums/home-page-dialog-state.enum';
import { showHomePageDialog } from '../../../../../utils/home-page-dialog-state-handlers';
import { useConfirmOtp } from './hooks/useConfirmOtp';
import { OtpConfirmation } from './OtpConfirmation';

interface Props {
  phoneNumber: string;
}

export const OtpConfirmationContainer: FC<Props> = ({ phoneNumber }) => {
  const { dispatch } = useContext(store);
  const confirmOtp = useConfirmOtp();
  const { mutation: sendConfirmationCode, isLoading: isResendingCode } = useSendPhoneConfirmationCode();

  const onOTPSubmit = useCallback(confirmOtp, [confirmOtp]);

  const onResend = useCallback(async () => sendConfirmationCode({ phoneNumber }), [sendConfirmationCode, phoneNumber]);

  const handleBackIconClick = useCallback(() => {
    showHomePageDialog(dispatch, HomePageDialogState.phoneConfirmation);
  }, [dispatch]);

  return (
    <OtpConfirmation
      onOTPSubmit={onOTPSubmit}
      onResend={onResend}
      phoneNumber={phoneNumber}
      handleBackIconClick={handleBackIconClick}
      isResendingCode={isResendingCode}
    />
  );
};
