import React, { FC, useContext } from 'react';
import { showErrorNotification } from '../../../../../components/ui/Notifications/Notifications';
import { store } from '../../../../../store/store';
import { ApiPath } from '../../../../../types/enums/api-path.enum';
import { HomePageDialogState } from '../../../../../types/enums/home-page-dialog-state.enum';
import { RecaptchaActionType } from '../../../../../types/enums/recaptcha-action-type.enum';
import { verifyRecaptcha } from '../../../../../utils/auth';
import { Fetch } from '../../../../../utils/fetch';
import { showHomePageDialog } from '../../../../../utils/home-page-dialog-state-handlers';
import { logger } from '../../../../../utils/logger';
import { RegisterPassword } from './RegisterPassword';

interface Props {
  email: string;
  isBookingFlow: boolean;
}

export const RegisterPasswordContainer: FC<Props> = ({ email, isBookingFlow }) => {
  const { dispatch, state } = useContext(store);

  const handleRegister = async ({ password }: { password: string }) => {
    try {
      await verifyRecaptcha(RecaptchaActionType.SIGN_UP, { email, password });
      await Fetch.post(ApiPath.REGISTER, { email, password }, { params: { language: state.language } });
      showHomePageDialog(dispatch, HomePageDialogState.confirmationEmailSent);
    } catch (error) {
      logger.error(error);
      showErrorNotification(error);
    }
  };

  const handleBackIconClick = () => {
    const homePageDialog: HomePageDialogState = isBookingFlow
      ? HomePageDialogState.loginEmailBookingFlow
      : HomePageDialogState.loginEmail;
    showHomePageDialog(dispatch, homePageDialog);
  };

  return (
    <RegisterPassword
      onDataSubmit={handleRegister}
      handleBackIconClick={handleBackIconClick}
      email={email}
    />
  );
};
