import React, { FC, ImgHTMLAttributes } from 'react';
import sizesStyle from '../../../styles/sizes.scss';
import spacingStyle from '../../../styles/spacing.scss';

export declare type IconType =
  'ic-back'
  | 'ic-close'
  | 'ic-book'
  | 'ic-eye-close'
  | 'ic-eye-open'
  | 'ic-form'
  | 'ic-logout'
  | 'ic-results'
  | 'ic-settings';

export interface IconProps extends ImgHTMLAttributes<HTMLImageElement> {
  className?: string;
  marginBottom?: boolean;
  marginRight?: boolean;
  onClick?: (...args: any) => void;
  type: IconType;
}

export const Icon: FC<IconProps> = ({ type, onClick, marginBottom, marginRight, alt, className }) => {
  const style = {
    width: sizesStyle.smallIconSize,
    height: sizesStyle.smallIconSize,
    marginBottom: marginBottom ? spacingStyle.marginSmall : undefined,
    marginRight: marginRight ? spacingStyle.marginSmall : undefined,
    cursor: 'pointer',
  };

  return <img
    alt={alt}
    src={`/assets/${type}.svg`}
    onClick={onClick}
    style={style}
    className={className}
  />;
};
