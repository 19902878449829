export enum BusinessErrorCode {
  ENTITY_CREATION_ERROR = 'ENTITY_CREATION_ERROR',
  DUPLICATE_ERROR = 'DUPLICATE_ERROR',
  ENTITY_UPDATE_ERROR = 'ENTITY_UPDATE_ERROR',
  MAX_REQUESTS_ERROR = 'MAX_REQUESTS_ERROR',
  NO_ACCESS_ERROR = 'NO_ACCESS_ERROR',
  EXTERNAL_SERVICE_ERROR = 'EXTERNAL_SERVICE_ERROR',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  LINK_USED = 'LINK_USED',
  LINK_EXPIRED = 'LINK_EXPIRED',
}
