import React, { useContext, useEffect, useState } from 'react';
import { setAppLanguage } from '../../bootstrap/i18n';
import { RECAPTCHA_TIMEOUT } from '../../constants/common';
import { store } from '../../store/store';
import { logger } from '../../utils/logger';
import { Error500 } from '../Errors/Error500';
import { Loader } from '../ui/Loader/Loader';
import { App } from './App';

export const AppContainer = () => {
  const [recaptchaReady, setRecaptchaReady] = useState(false);
  const [recaptchaTimeout, setRecaptchaTimeout] = useState(false);
  const { state } = useContext(store);

  useEffect(() => {
    setAppLanguage(state.language).catch(logger.error);
  }, [state.language]);

  const timeoutId = setTimeout(() => {
    setRecaptchaTimeout(true);
  }, RECAPTCHA_TIMEOUT);

  grecaptcha.ready(() => {
    clearTimeout(timeoutId);
    setRecaptchaReady(true);
  });

  if (recaptchaTimeout) {
    return <Error500 />;
  }

  if (!recaptchaReady || !state.currentUserReady) {
    return <Loader fullscreen size='large' />;
  }

  return <App />;
};
