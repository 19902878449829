import { useContext } from 'react';
import { useQuery } from 'react-query';
import { store } from '../store/store';
import { PatientBookingData } from '../types/api-types';
import { ApiPath } from '../types/enums/api-path.enum';
import { ApiQueries } from '../types/enums/api-queries.enum';
import { getUserToken } from '../utils/auth';
import { Fetch, FetchErrorResponse } from '../utils/fetch';

export const usePatient = () => {
  const { state } = useContext(store);
  const { data, isLoading, error } = useQuery<PatientBookingData, ApiQueries, FetchErrorResponse>(ApiQueries.PATIENT, async () => {
    const { data: responseData } = await Fetch.get<PatientBookingData>(
      ApiPath.PATIENT_ME,
      { headers: { Authorization: await getUserToken(state) } }
    );

    return responseData;
  });

  return {
    patient: data,
    isLoadingPatient: isLoading,
    fetchingPatientError: error,
  };
};
