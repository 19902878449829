import { Input, Select } from 'antd';
import { PhoneNumberUtil } from 'google-libphonenumber';
import React, { CSSProperties, FC, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';
import { MAX_PHONE_NUMBER_LENGTH } from '../../../constants/common';
import { PHONE_NUMBER_COUNTRY_CODES } from '../../../routes/BookingCalendlyRedirectPage/constants';
import { handleInputFormat } from '../../../utils/input-handlers';
import { WithLabelAndError, WithLabelAndErrorProps } from '../hoc/WithLabelAndError';
import style from './PhoneNumberInput.module.scss';

const { REACT_APP_DEFAULT_PHONE_NUMBER_COUNTRY_CODE } = process.env;
const phoneUtil = PhoneNumberUtil.getInstance();

export interface PhoneNumberInputProps extends WithLabelAndErrorProps {
  className?: string;
  control: Control;
  defaultValue?: string;
  disabled?: boolean;
  inputStyle?: CSSProperties;
  name: string;
  rules?: any;
}

export interface PhoneNumberComponentProps extends PhoneNumberInputProps {
  onBlur: () => void;
  onChange: (...event: any[]) => void;
  value: any;
  [x: string]: any;
}

const { Option } = Select;

const PhoneNumberComponent: FC<PhoneNumberComponentProps> = props => {
  let defaultNationalNumber, defaultCountryCode = REACT_APP_DEFAULT_PHONE_NUMBER_COUNTRY_CODE;

  try {
    if (props.value) {
      const phoneNumber = phoneUtil.parseAndKeepRawInput(props.value);
      defaultNationalNumber = phoneNumber.getNationalNumber()?.toString();
      defaultCountryCode = phoneNumber.getCountryCode()?.toString() || REACT_APP_DEFAULT_PHONE_NUMBER_COUNTRY_CODE;
    }
  // tslint:disable-next-line:no-empty
  } catch {}

  const [countryCode, setCountryCode] = useState('+' + defaultCountryCode);
  const [nationalNumber, setNationalNumber] = useState(defaultNationalNumber);
  const { required, label, error, onChange } = props;
  (nationalNumber !== undefined) && onChange(countryCode + nationalNumber);

  return (
    <WithLabelAndError error={error} required={required} label={label} className={props.className}>
      <Input.Group className={style.inputGroup}>
        <Select
          defaultValue={countryCode}
          className={style.countryCode}
          dropdownClassName={style.countryCodeDropdown}
          onChange={value => setCountryCode(value)}
          notFoundContent={null}
          disabled={props.disabled}
          showSearch
        >
          {
            PHONE_NUMBER_COUNTRY_CODES.map(code => <Option key={code} value={'+' + code}>{'+' + code}</Option>)
          }
        </Select>
        <Input
          className={style.nationalNumber}
          defaultValue={nationalNumber}
          onChange={({ target }) => setNationalNumber(target.value)}
          disabled={props.disabled}
          onInput={handleInputFormat({ maxLength: MAX_PHONE_NUMBER_LENGTH })}
          type='number'
        />
      </Input.Group>
    </WithLabelAndError>
  );
};

export const PhoneNumberInput: FC<PhoneNumberInputProps> = props => (
  <Controller
    as={PhoneNumberComponent}
    rules={{ required: props.required }}
    {...props}
  />
);
