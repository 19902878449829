export const RECAPTCHA_TIMEOUT = 60 * 1000; // 1 minute

export const MAX_PHONE_NUMBER_LENGTH = 12;
export const MAX_INPUT_LENGTH = 256;
export const MAX_POSTAL_CODE_LENGTH = 16;
export const MAX_DOCUMENT_LENGTH = 20;
export const MAX_VEHICLE_REGISTRATION_PLATE_LENGTH = 20;
export const PHONE_VERIFICATION_CODE_LENGTH = 6;

export const SUPPORT_EMAIL = 'support@pandia.health';
