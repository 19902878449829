import { useContext } from 'react';
import { queryCache, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { showErrorNotification } from '../../../components/ui/Notifications/Notifications';
import { store } from '../../../store/store';
import { StoreActionType } from '../../../store/store-types';
import { PatientConfirmBookingDto, SuccessResponse } from '../../../types/api-types';
import { ApiPath } from '../../../types/enums/api-path.enum';
import { ApiQueries } from '../../../types/enums/api-queries.enum';
import { HomePageDialogState } from '../../../types/enums/home-page-dialog-state.enum';
import { Path } from '../../../types/enums/path.enum';
import { PaymentStatus } from '../../../types/enums/payment-status.enum';
import { getUserToken } from '../../../utils/auth';
import { Fetch, FetchErrorResponse } from '../../../utils/fetch';
import { showHomePageDialog } from '../../../utils/home-page-dialog-state-handlers';
import { logger } from '../../../utils/logger';
import { CalendlyRedirectParams } from '../interfaces/calendly-redirect-params.interface';

interface Args {
  patientData: PatientConfirmBookingDto;
  redirectParams: CalendlyRedirectParams;
}

export const useConfirmBooking = () => {
  const { state, dispatch } = useContext(store);
  const { push } = useHistory();
  const [mutation, mutationOptions] = useMutation<SuccessResponse,
    Args,
    FetchErrorResponse>(async ({ patientData, redirectParams }: Args) => {
    const body: PatientConfirmBookingDto = {
      ...patientData,
      calendlyEventTypeUuid: redirectParams.event_type_uuid,
      calendlyInviteeUuid: redirectParams.invitee_uuid,
    };

    const { data } = await Fetch.post<SuccessResponse>(
      ApiPath.CONFIRM_BOOKING,
      body,
      { headers: { Authorization: await getUserToken(state) } },
    );

    return data;
  });

  if (!mutationOptions.isLoading && mutationOptions.error?.response) {
    logger.error(mutationOptions.error.response.data);
    showErrorNotification(mutationOptions.error.response.data, {
      defaultNotification: 'bookingConfirmationFailed',
    });
  }

  if (!mutationOptions.isLoading && mutationOptions.isSuccess) {
    queryCache.invalidateQueries(ApiQueries.PATIENT);
    queryCache.invalidateQueries(ApiQueries.DOCTORS);

    dispatch({
      type: StoreActionType.SET_PAYMENT_STATUS,
      payload: PaymentStatus.SUCCESS,
    }); // TODO remove success status, payments first
    showHomePageDialog(dispatch, HomePageDialogState.endSuccess); // TODO remove success dialog

    push(Path.HOME); // TODO redirect to payments
    // IMPORTANT reset to avoid showing notifications on parent component rerender
    mutationOptions.reset();
  }

  return mutation;
};
