import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from '../../hooks/useTranslation';
import { ErrorsLocales } from '../../locales/locales.interfaces';
import { HttpStatus } from '../../types/enums/http-status.enum';
import { Button } from '../ui/Button/Button';
import style from './Error500.module.scss';


export const Error500 = () => {
  const { goBack } = useHistory();
  const { t } = useTranslation<ErrorsLocales>('errors');

  return (
    <div className={style.container}>
      <h1>{HttpStatus.INTERNAL_SERVER_ERROR}</h1>
      <p className={style.info}>{t('error500text')}</p>
      <Button type='primary' onClick={() => goBack()}>{t('goBack')}</Button>
    </div>
  );
};
